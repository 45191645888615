import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import { AvailableVendors } from '../models/IDomain';

const DragHandleSpan = styled.span`
    margin-right: 10px;
    display: inline-block;
`

const DragHandle = SortableHandle(() => <DragHandleSpan>::</DragHandleSpan>);

const StyledList = styled.div`
    border-radius: 4px;
    background: #f3f3f3;
    border: 1px solid hsl(0,0%,80%);
    list-style: none;
    overflow: hidden;
`

const StyledListItem = styled.li`
    cursor: row-resize;
    border-bottom: 1px solid hsl(0,0%,80%);
    padding: 14px 20px;
    list-style: none;
    background: white;
    line-height: 1;
    text-transform: capitalize;
    
    &:last-child {
        border-bottom: none;
    }
`

const SortableVendor = SortableElement(({ value }: { value: any; }) => <StyledListItem><DragHandle />{value}</StyledListItem>)

const SortableVendors = SortableContainer(({ items }: { items: AvailableVendors[] }) => {
    return (
        <StyledList>
            {items.map((value, index) => (
                <SortableVendor key={value} index={index} value={value} />
            ))}
        </StyledList>
    )
});

export default SortableVendors;
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://58u9nljx3c.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "chargeBeeWebhookAPI",
            "endpoint": "https://v0er0qrgpi.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "chargebeeSubscriptionAPI",
            "endpoint": "https://varmsuvmu5.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "domainAPI",
            "endpoint": "https://2h1gmr6hue.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "linkToChargebeeAPI",
            "endpoint": "https://qrvk9oq0cj.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "productAPI",
            "endpoint": "https://7fs2aenf6g.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "userSubscriptionsAPI",
            "endpoint": "https://ki8momnvf3.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:3183aa30-94f3-4749-b53a-054584c1d9cb",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_0q1qjAs0z",
    "aws_user_pools_web_client_id": "3c58onrhmtdfb2ajrb00enf2k0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "c9sscriptswebapp-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "chargebeeSubscription-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "domain-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "product-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "subscriptionUser-prod",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;

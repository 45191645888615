import { Auth } from 'aws-amplify';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import UserContext from '../UserContext';
import QRCode from 'qrcode.react'
import { Button } from '@material-ui/core';
import ConfirmModal from '../common/components/ConfirmModal';
import ErrorMessage from '../common/components/ErrorMessage';

const QrCodeWrapper = styled.div`
    text-align: center;
    margin-bottom: 10px;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledButton = styled.button`
    cursor: pointer;
    margin: 4;
    border: 1px solid #ddd;
    width: 225px;
    &:hover {
      background-color: rgba(0, 0, 0, .1);
    }
`;

const StyledInput = styled.input`
    padding: 8px;
    height: 40px;
    width: 225px;
    border: 1px solid #ddd;
`;

const TwoFactorSettings = () => {
    const [qrCode, setQrCode] = useState<string>('');
    const [challengeAnswer, setChallengeAnswer] = useState<string>('');
    const [showPreferred, setShowPreferred] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('')

    const userContext = useContext(UserContext);

    const addTTOP = useCallback(() => {
        const { user } = userContext;
        setErrorMessage('');
        Auth.setupTOTP(user)
            .then((code) => {
                const authCode = "otpauth://totp/AWSCognito:"+ user?.getUsername() + "?secret=" + code + "&issuer=AWSCognito";
                setQrCode(authCode);
                setShowPreferred(true);
            });
    }, [userContext]);

    const setPreferredMFA = useCallback((authType: "TOTP" | "SMS" | "NOMFA") => {
        const { user } = userContext;
        setErrorMessage('');
        Auth.verifyTotpToken(user, challengeAnswer)
            .then(() => {
                return Auth.setPreferredMFA(user, authType)
                    .then(data => console.log('data from verify...: ', data))
            }).catch(err => {
                setErrorMessage(err.message);
            })
    }, [userContext, challengeAnswer])

    return (
        <>
            <Button
                color="primary" 
                variant="contained" 
                disableElevation
                onClick={addTTOP}
            >Update TOTP</Button>
            {(qrCode !== '' || showPreferred) && (
                <ConfirmModal
                    title="Update TTOP"
                    onCancel={() => {
                        setQrCode('');
                        setShowPreferred(false);
                    }}
                >
                    <ButtonContainer>
                        {(qrCode !== '') && (
                            <QrCodeWrapper>
                                <QRCode value={qrCode} />
                            </QrCodeWrapper>
                        )}
                        {showPreferred && (
                            <>
                                <ButtonContainer>
                                    <StyledButton onClick={() => setPreferredMFA('TOTP')}>
                                        Prefer TOTP
                                    </StyledButton>
                                    <StyledButton onClick={() => setPreferredMFA('SMS')}>
                                        <p>Prefer SMS</p>
                                    </StyledButton>
                                    <StyledInput
                                        placeholder='TOTP Code'
                                        onChange={({ target: { value }}) => setChallengeAnswer(value)}
                                    />
                                </ButtonContainer>
                                {errorMessage && (
                                    <ErrorMessage error={errorMessage} />
                                )}
                            </>
                        )}
                    </ButtonContainer>
                </ConfirmModal>
            )}
        </>
    );
}
export default TwoFactorSettings;

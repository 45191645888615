import IDomain, { IWidgetCustomization, WidgetOrderBy, WidgetPopupPosition, WidgetType, WIDGET_DEFAULTS } from "../models/IDomain";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from "../redux/hooks";
import DomainService from "../services/DomainService";
import { fetchDomains } from "../redux/subscriptionsSlice";
import Input, { InputContainer, FullWidthInputLabel, InputLabel } from "../common/components/Input";
import InputColor from 'react-input-color';
import Select from 'react-select'
import IValueAndLabel from "../common/models/IValueAndLabel";
import { Container, FormWrapper, FormColumn, FormRow } from "../common/components/Form";
import { Button, Checkbox } from '@material-ui/core';
import { RESOURCE_URL_ROOT } from "./constants";
import Http from "../services/Http";
import usePrevious from "../common/hooks/usePrevious";
import * as _ from 'underscore';
import DomainFormValidationService from "../services/DomainFormValidationService";
import { Prompt } from "react-router-dom";

const WidgetTypeOptions: IValueAndLabel<WidgetType>[] = [
    { value: 'EMBEDDED', label: 'Embedded' },
    { value: 'POPUP', label: 'Popup' },
    { value: 'SEARCH', label: 'Search' }
];

const WidgetOrderByOptions: IValueAndLabel<WidgetOrderBy>[] = [
    { value: 'PART_NUMBER', label: 'Part Number (default)' },
    { value: 'PRICE_LOW', label: 'Price (low to high)' },
    { value: 'PRICE_HIGH', label: 'Price (high to low)' },
    { value: 'QUANTITY', label: 'Available Quantity' },
    { value: 'VENDOR', label: 'Vendor' }
];

const WidgetPopupPositionOptions: IValueAndLabel<WidgetPopupPosition>[] = [
    { value: 'CENTER', label: 'Center' },
    { value: 'LEFT', label: 'Left' },
    { value: 'RIGHT', label: 'Right' },
];
interface IProps {
    subscriptionId: string;
    domain: IDomain;
    onSaved: (savedDomain: IDomain) => void;
}

const WidgetForm = ({
    domain,
    subscriptionId,
    onSaved,
}: IProps) => {
    const [formData, setFormData] = useState<IWidgetCustomization>();
    const previousFormData = usePrevious(formData);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const dispatch = useAppDispatch();

    const [jsCode, setJsCode] = useState<string>('');
    const [cssCode, setCssCode] = useState<string>('');

    const formErrors = useMemo(() => {
        return formData ? DomainFormValidationService.getWidgetValidationErrors(formData) : null;
    }, [formData]);


    useEffect(() => {
        const jsPath = `${RESOURCE_URL_ROOT}${domain.domainId}/c9s.js`;
        const cssPath = `${RESOURCE_URL_ROOT}${domain.domainId}/c9s.css`;

        async function fetchCode() {
            try {
                const jsCode = await (await Http(jsPath + '?t=' + Date.now())).text()
                const cssCode = await (await Http(cssPath)).text()
                setJsCode(`<script type="text/javascript">\n${jsCode}\n</script>`);
                setCssCode(`<style>\n${cssCode}\n</style>`);
            } catch (e) {
                // TODO error handling
                console.error(e);
            }
        }

        fetchCode();
    }, [domain.domainId])

    useEffect(() => {
        if (previousFormData && !_.isEqual(previousFormData, formData)) {
            setIsDirty(true);
        }
    }, [formData, previousFormData])

    useEffect(() => {
        if (!domain) {
            return;
        }
        setFormData({
            ...WIDGET_DEFAULTS,
            ...domain.widgetCustomization,
            colors: {
                ...WIDGET_DEFAULTS.colors,
                ...(domain.widgetCustomization?.colors || {}),
            }
        });
    }, [domain]);

    const SubmitForm = useCallback((event) => {
        event.preventDefault();
        if (!formData || formErrors) {
            if (!formSubmitted) {
                setFormSubmitted(true);
            }
            return;
        }
        setIsSubmitting(true);
        DomainService.updateDomain({
            ...domain,
            widgetCustomization: formData,
        } as IDomain)
            .then(({ data: savedDomain }) => {
                dispatch(fetchDomains())
                setIsSubmitting(false);
                setIsDirty(false);
                onSaved?.(savedDomain);
            })
            .catch(error => {
                // TODO error handling
                console.log('error: ', error.response);
                setIsSubmitting(false);
            });
    }, [formData, domain, dispatch, onSaved, formErrors, formSubmitted]);

    const selectedWidgetTypeOption: IValueAndLabel<WidgetType> = useMemo(() => {
        return WidgetTypeOptions.find(option => option.value === formData?.type) || null;
    }, [formData]);

    const selectedWidgetOrderByOption: IValueAndLabel<WidgetOrderBy> = useMemo(() => {
        return WidgetOrderByOptions.find(option => option.value === formData?.orderBy) || null;
    }, [formData]);

    const selectedWidgetPopupPosition: IValueAndLabel<WidgetPopupPosition> = useMemo(() => {
        return WidgetPopupPositionOptions.find(option => option.value === formData?.popupPosition) || null;
    }, [formData]);

    return (
        <Container>
            {formData && (
                <>
                    <FormWrapper onSubmit={SubmitForm}>
                        <Prompt
                            when={isDirty}
                            message="You have unsaved changed, are you sure you want to leave?"
                        />
                        <FormColumn>
                            <FormRow>
                                <FullWidthInputLabel>Choose the type of widget</FullWidthInputLabel>
                                <Select
                                    value={selectedWidgetTypeOption}
                                    options={WidgetTypeOptions}
                                    onChange={(option) => { setFormData({ ...formData, type: option.value }) }}
                                />
                            </FormRow>
                            <FormRow>
                                <Input
                                    label={
                                        <>
                                            <span>Buy Now Button Text</span>
                                        </>
                                    }
                                    id={"buyNowButtonText"}
                                    value={formData.text.buyNowButton}
                                    type="text"
                                    onChange={({ target: { value } }) => setFormData({ ...formData, text: { ...formData.text, buyNowButton: value } })}
                                    formSubmitted={formSubmitted}
                                    error={formErrors?.buyNowButtonText}
                                />
                            </FormRow>
                            <FormRow>
                                <Input
                                    label={
                                        <>
                                            <span>Query Parameter Name</span>
                                        </>
                                    }
                                    id={"queryParameterName"}
                                    value={formData.text.queryParameterName}
                                    type="text"
                                    onChange={({ target: { value } }) => setFormData({ ...formData, text: { ...formData.text, queryParameterName: value } })}
                                    formSubmitted={formSubmitted}
                                    error={formErrors?.queryParameterNameText}
                                />
                            </FormRow>
                            <FormRow>
                                <FullWidthInputLabel>Header Color</FullWidthInputLabel>
                                <InputColor
                                    initialValue={formData.colors.headerBackground}
                                    onChange={(color: any) => {
                                        if (color.hex.toLowerCase() !== formData.colors.headerBackground.toLowerCase()) {
                                            setFormData({ ...formData, colors: { ...formData.colors, headerBackground: color.hex } })
                                        }
                                    }}
                                />
                            </FormRow>
                            <FormRow>
                                <FullWidthInputLabel>Buy Now Button Color</FullWidthInputLabel>
                                <InputColor
                                    initialValue={formData.colors.buyNowButton}
                                    onChange={(color: any) => {
                                        if (color.hex.toLowerCase() !== formData.colors.buyNowButton.toLowerCase()) {
                                            setFormData({ ...formData, colors: { ...formData.colors, buyNowButton: color.hex } })
                                        }
                                    }}
                                />
                            </FormRow>
                            <FormRow>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.hideIfNoStock}
                                        onChange={() => setFormData({ ...formData, hideIfNoStock: !formData.hideIfNoStock })}
                                        name="hide-if-no-stock"
                                        color="primary"
                                    />
                                    <InputLabel>Hide rows that do not have stock</InputLabel>
                                </InputContainer>
                            </FormRow>
                            <FormRow>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.showCopyResultsButton}
                                        onChange={() => setFormData({ ...formData, showCopyResultsButton: !formData.showCopyResultsButton })}
                                        name="show-copy-results-button"
                                        color="primary"
                                    />
                                    <InputLabel>Include button to copy results to clipboard</InputLabel>
                                </InputContainer>
                            </FormRow>
                            <FormRow>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.showExportResultsButton}
                                        onChange={() => setFormData({ ...formData, showExportResultsButton: !formData.showExportResultsButton })}
                                        name="show-export-results-button"
                                        color="primary"
                                    />
                                    <InputLabel>Include button to export results to csv file</InputLabel>
                                </InputContainer>
                            </FormRow>
                            <FormRow>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.combinePartResults}
                                        onChange={() => setFormData({ ...formData, combinePartResults: !formData.combinePartResults })}
                                        name="combine-part-results"
                                        color="primary"
                                    />
                                    <InputLabel>Combine Part Results</InputLabel>
                                </InputContainer>
                            </FormRow>
                        </FormColumn>
                        <FormColumn>
                            <FormRow>
                                <FullWidthInputLabel>Choose the order of results</FullWidthInputLabel>
                                <Select
                                    value={selectedWidgetOrderByOption}
                                    options={WidgetOrderByOptions}
                                    onChange={(option) => { setFormData({ ...formData, orderBy: option.value }) }}
                                />
                            </FormRow>
                            <FormRow>
                                <FullWidthInputLabel>Select columns to display in results</FullWidthInputLabel>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displayBuyNowButton}
                                        onChange={() => setFormData({ ...formData, displayBuyNowButton: !formData.displayBuyNowButton })}
                                        name="display-buy-now-button"
                                        color="primary"
                                    />
                                    <InputLabel>Buy Now Button</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displaySellerCountryCode}
                                        onChange={() => setFormData({ ...formData, displaySellerCountryCode: !formData.displaySellerCountryCode })}
                                        name="display-seller-country"
                                        color="primary"
                                    />
                                    <InputLabel>Country</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displayDatasheet}
                                        onChange={() => setFormData({ ...formData, displayDatasheet: !formData.displayDatasheet })}
                                        name="display-datasheet"
                                        color="primary"
                                    />
                                    <InputLabel>Datasheet</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displayMOQ}
                                        onChange={() => setFormData({ ...formData, displayMOQ: !formData.displayMOQ })}
                                        name="display-moq"
                                        color="primary"
                                    />
                                    <InputLabel>MOQ</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displayPackaging}
                                        onChange={() => setFormData({ ...formData, displayPackaging: !formData.displayPackaging })}
                                        name="display-packaging"
                                        color="primary"
                                    />
                                    <InputLabel>Packaging</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displayPartNumber}
                                        onChange={() => setFormData({ ...formData, displayPartNumber: !formData.displayPartNumber })}
                                        name="display-part-number"
                                        color="primary"
                                    />
                                    <InputLabel>Part Number</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displayPrice}
                                        onChange={() => setFormData({ ...formData, displayPrice: !formData.displayPrice })}
                                        name="display-price"
                                        color="primary"
                                    />
                                    <InputLabel>Price</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displaySeller}
                                        onChange={() => setFormData({ ...formData, displaySeller: !formData.displaySeller })}
                                        name="display-seller"
                                        color="primary"
                                    />
                                    <InputLabel>Distributor</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displayInventoryLevel}
                                        onChange={() => setFormData({ ...formData, displayInventoryLevel: !formData.displayInventoryLevel })}
                                        name="display-inventory-level"
                                        color="primary"
                                    />
                                    <InputLabel>Stock</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displayUpdatedDate}
                                        onChange={() => setFormData({ ...formData, displayUpdatedDate: !formData.displayUpdatedDate })}
                                        name="display-updated-date"
                                        color="primary"
                                    />
                                    <InputLabel>Updated</InputLabel>
                                </InputContainer>
                                <InputContainer>
                                    <Checkbox
                                        checked={formData.displayDeltaDate}
                                        onChange={() => setFormData({ ...formData, displayDeltaDate: !formData.displayDeltaDate })}
                                        name="display-delta-date"
                                        color="primary"
                                    />
                                    <InputLabel>Updated Delta Date</InputLabel>
                                </InputContainer>
                            </FormRow>
                            <FormRow>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    disabled={isSubmitting}
                                >Save Widget
                                </Button>
                            </FormRow>
                        </FormColumn>
                    </FormWrapper>
                </>
            )}
        </Container>
    )
}
export default WidgetForm;

const resouceRootUrlDev = 'https://c9sscripts175133-develop.s3-us-west-2.amazonaws.com/';
const resouceRootUrlProd = 'https://c9sscriptswebapp-prod.s3-us-west-2.amazonaws.com/';

const lambdaFunctionNameDev = 'quicksight-embedded-url-generator-develop';
const lambdaFunctionNameProd = 'quicksight-embedded-url-generator-prod';

export const RESOURCE_URL_ROOT = process.env.REACT_APP_USER_BRANCH === 'production'
    ? resouceRootUrlProd
    : resouceRootUrlDev;

export const LAMBDA_FUNCTION_NAME = process.env.REACT_APP_USER_BRANCH === 'production'
    ? lambdaFunctionNameProd
    : lambdaFunctionNameDev;

export const TEST_MPN_NEXAR = 'ASPIAIG-F5020-R16M-T';
export const TEST_MANUFACTURER_NEXAR = 'Abracon';

export const TEST_MPN_OCTOPART = 'ASPIAIG-F5020-R16M-T';
export const TEST_MANUFACTURER_OCTOPART = 'Abracon';

export const TEST_MPN_WALMART = '880297531';
export const TEST_MANUFACTURER_WALMART = 'Arcade1Up';

export const TEST_MPN_SUPPLYFRAME = 'BAV99W,115';
export const TEST_MANUFACTURER_SUPPLYFRAME = 'Nexperia';

export const MPN_LOCAL_STORAGE_KEY = 'widget-test-mpn';
export const MANUFACTURER_LOCAL_STORAGE_KEY = 'widget-test-manufacturer';

export const AWS_REGION = 'us-west-2';
export const AWS_ACCESS_KEY_ID = 'AKIASCOGYJQMJQRKWCXH';
export const AWS_SECRET_ACCESS_KEY = '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';

export const DOMAIN_ID = '284-0f96d870697c';
export const SUBSCRIPTION_ID = '16CI1cSOSXtF6cPf';
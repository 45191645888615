import { Auth } from 'aws-amplify'
import { IAuthUser } from '../models/IAuthUser';

export default class UserService {
    public static signIn(username: string, password: string): Promise<IAuthUser | any> {
        return Auth.signIn(username, password);
    }

    public static signOut() {
        return Auth.signOut();
    }

    public static getAuthenticatedUser(): Promise<IAuthUser | any> {
        return Auth.currentAuthenticatedUser();
    }
}
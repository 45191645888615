import React from 'react';
import { IAuthUser } from './models/IAuthUser';

interface IUserContext {
    user: IAuthUser | null,
    updateCurrentUser: (user?: IAuthUser) => void;
    isLoaded: boolean;
}

const UserContext = React.createContext<IUserContext>({
    user: null,
    updateCurrentUser: () => {},
    isLoaded: false,
});

export default UserContext;
import styled from "styled-components";
import MediaQueries from "../style-vars/MediaQueries";

export const Container = styled.div`
    width: 100%;
`;

export const FormWrapper = styled.form`
    display: flex;
    width: 100%;

    @media (min-width: ${MediaQueries.MD_MIN}) {
        > * {
            width: 50%;
        }
    }
`;

export const FormColumn = styled.div`
    width: 50%;
    max-width: 600px;
`

export const FormRow = styled.div`
    margin-bottom: 22px;
    max-width: 400px;
`

export {}
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import Colors from '../style-vars/Colors';

interface RootProps {
    variant: 'inline' | 'block' | 'cover';
    margin: number;
}
const Root = styled.div<RootProps>`
    ${({ variant, margin }) => css`
        display: ${variant === 'inline' ? 'inline-block' : 'block'};
        text-align: center;
        margin: ${margin}px auto;
    `}
`

export const LoadingTextContainer = styled.div`

`

const RingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const borderWidthLarge = 8;
const borderWidthMedium = 6;
const borderWidthSmall = 4;
const sizeLarge = 64;
const sizeMedium = 48;
const sizeSmall = 32;

export const Ring = styled.div<{ size: 'small' | 'medium' | 'large'; }>`
    ${({ size }) => {
        let innerDimensions = sizeSmall;
        let borderWidth = borderWidthSmall;
        if (size === 'medium') {
            innerDimensions = sizeMedium;
            borderWidth = borderWidthMedium;
        }
        if (size === 'large') {
            innerDimensions = sizeLarge;
            borderWidth = borderWidthLarge;
        }

        return css`
            display: inline-block;
            position: relative;
            width: ${innerDimensions + 2*borderWidth}px;
            height: ${innerDimensions + 2*borderWidth}px;
        
            div {
                box-sizing: border-box;
                display: block;
                position: absolute;
                width: ${innerDimensions}px;
                height: ${innerDimensions}px;
                margin: ${borderWidth}px;
                border: ${borderWidth}px solid ${Colors.BRAND_PRIMARY_DARK};
                border-radius: 50%;
                animation: ${RingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                border-color: ${Colors.BRAND_PRIMARY_DARK} transparent transparent transparent;

                &:nth-child(1) {
                    animation-delay: -0.45s;
                }

                &:nth-child(2) {
                    animation-delay: -0.3s;
                }

                &:nth-child(3) {
                    animation-delay: -0.15s;
                }
            }
        `
    }}
`


interface IProps {
    show: boolean;
    margin?: number;
    className?: string;
    text?: string;
    size?: 'small' | 'medium' | 'large';
    variant?: 'inline' | 'block' | 'cover';
}
const LoadingIndicator = ({
    show,
    text = '',
    className = '',
    size = 'medium',
    variant = 'inline',
    margin = 0,
}: IProps) => {
    return show && (
        <Root
            className={className}
            variant={variant}
            margin={margin}
        >
            <Ring
                size={size}
            ><div></div><div></div><div></div><div></div></Ring>
            {text && (<LoadingTextContainer>{text}</LoadingTextContainer>)}
        </Root>
    )
}
export default LoadingIndicator;
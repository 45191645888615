import React from 'react';
import { Hub } from 'aws-amplify';
import Router from '../Router';
import UserContext from '../UserContext';
import { IAuthUser } from '../models/IAuthUser';
import UserService from '../services/UserService';
import { ThemeProvider } from '@material-ui/styles';
import { RootState } from '../store';
import MaterialTheme from './style-vars/MaterialTheme';
import { fetchDomains, fetchSubscriptions, ISubscriptionsState, setCurrentSubscription } from '../redux/subscriptionsSlice';
import './App.css'
import { connect } from 'react-redux';
import Footer from './Footer';
import styled, { createGlobalStyle } from 'styled-components';
import { SELECTED_SUBSCRIPTION_ID_LOCAL_KEY } from '../subscriptions/constants';
import MediaQueries from './style-vars/MediaQueries';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 12px;

    @media (min-width: ${MediaQueries.SM_MIN}) {
      font-size: 14px;
    }
  }
`

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
`

interface IState {
  currentUser: IAuthUser | null;
  isLoaded: boolean;
}
interface IProps {
  subscriptions: ISubscriptionsState;
  fetchSubscriptions: () => void;
  fetchDomains: () => void;
  setCurrentSubscription: (subscriptionId: string) => void;
}
class App extends React.Component<IProps, IState> {
  state = {
    currentUser: null,
    isLoaded: false
  }

  componentDidMount() {
    this.updateCurrentUser()
    Hub.listen('auth', this);
    this.props.fetchSubscriptions();
  }

  componentDidUpdate() {
    const { 
      subscriptions: { subscriptionsList, currentSubscription },
      setCurrentSubscription,
      fetchDomains,
     } = this.props;
  
     if (subscriptionsList.items.length && !currentSubscription.subscription) {
      const userSetId = localStorage.getItem(SELECTED_SUBSCRIPTION_ID_LOCAL_KEY);
      const userSetSubscription = subscriptionsList.items.find(sub => sub.subscriptionId === userSetId)?.subscriptionId;
      setCurrentSubscription(userSetSubscription || subscriptionsList.items[0].subscriptionId);
      fetchDomains();
     }
  }

  onHubCapsule(capsule: {
    channel: string;
    payload: {
      event: string;
    };
  }) {
    const { channel, payload } = capsule;
    if (channel === 'auth' && payload.event !== 'signIn') {
      this.updateCurrentUser()
    }
  }
  
  updateCurrentUser = async (user?: IAuthUser) => {
    if (user) {
      this.setState({ currentUser: user })
      return
    }
    try {
      const user = await UserService.getAuthenticatedUser()
      this.setState({ currentUser: user as IAuthUser, isLoaded: true })
    } catch (err) {
      this.setState({ currentUser: null, isLoaded: true })
    }
  }

  render() {
    return (
      <UserContext.Provider value={{
        user: this.state.currentUser,
        updateCurrentUser: this.updateCurrentUser,
        isLoaded: this.state.isLoaded
      }}>
        <ThemeProvider theme={MaterialTheme}>
          <AppWrapper>
            <GlobalStyle />
            <Router />
            <Footer />
          </AppWrapper>
        </ThemeProvider>
      </UserContext.Provider>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  subscriptions: state.subscriptions
})

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptions: () => dispatch(fetchSubscriptions()),
  fetchDomains: () => dispatch(fetchDomains()) ,
  setCurrentSubscription: (subscriptionId) => dispatch(setCurrentSubscription(subscriptionId))
})

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Input, { InputLabel, FullWidthInputLabel } from '../common/components/Input';
import IDomain, { AvailableVendors, IDomainEditableFields, IVendorDetails, WIDGET_DEFAULTS } from '../models/IDomain';
import styled from 'styled-components';
import SellerDetailsForm from './SellerDetailsForm';
import UserContext from '../UserContext';
import DomainService from '../services/DomainService';
import { useAppDispatch } from '../redux/hooks';
import { fetchDomains } from '../redux/subscriptionsSlice';
import * as _ from 'underscore';
import SortableVendors from './SortableVendors';
import arrayMove from 'array-move';
import Checkbox from '@material-ui/core/Checkbox';
import { Container, FormWrapper, FormColumn, FormRow } from "../common/components/Form";
import { Button } from '@material-ui/core';
import DomainFormValidationService from '../services/DomainFormValidationService';
import './DomainForm.css';
import usePrevious from '../common/hooks/usePrevious';
import { Prompt } from 'react-router-dom';

const cloneDeep = require('clone-deep');

const SortVendorSection = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
`

const StyledSellerDetailsForm = styled(SellerDetailsForm)`
    margin-top: 40px;
    margin-bottom: 24px;
`

const VendorNameLabel = styled.span`
    text-transform: capitalize;
`

function createVendorDetails(): IVendorDetails {
    return {
        apiKey: '',
        apiSecret: '',
        storeId: '',
        excludeSellerIds: '',
        sellerIdOrder: '',
        showPrice: true,
        hideIfNoStock: false,
    }
}

const allVendors: AvailableVendors[] = [
    //'aliexpress',
    'nexar',
    'octopart',
    // 'supplyframe',
    // 'walmart',
];

interface IProps {
    domain?: IDomain | null;
    subscriptionId: string;
    onSaved?: (domain: IDomain) => void;
}
const DomainForm = ({
    domain, 
    subscriptionId,
    onSaved,
}: IProps) => {
    const [formData, setFormData] = useState<IDomainEditableFields>();
    const previousFormData = usePrevious(formData);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const userContext = useContext(UserContext);
    const dispatch = useAppDispatch();
    // Todo subscription options for new domain
    
    useEffect(() => {
        if (domain) {
            setFormData({
                ...cloneDeep(domain),
                vendorOrder: domain.vendorOrder || (Object.keys(domain.onlineVendors) as AvailableVendors[])
                    .filter(vName => !!domain.onlineVendors[vName]),
                widgetCustomization: domain.widgetCustomization || {
                    ...WIDGET_DEFAULTS
                }
            });
        } else {
            setFormData({
                alias: '',
                domainName: '',
                onlineVendors: {
                    walmart: null,
                    octopart: null,
                    aliexpress: null,
                },
                vendorOrder: [],
                widgetCustomization: {
                    ...WIDGET_DEFAULTS
                }
            })
        }
    }, [domain, subscriptionId]);

    useEffect(() => {
        if (previousFormData && !_.isEqual(previousFormData, formData)) {
            setIsDirty(true);
        }
    }, [formData, previousFormData])

    const toggleIncludeVendor = useCallback((vendor: AvailableVendors) => {
        if (!formData) {
            return;
        }
        if (!formData.vendorOrder.includes(vendor)) {
            setFormData({
                ...formData,
                vendorOrder: [...formData.vendorOrder, vendor],
                onlineVendors: {
                    ...formData.onlineVendors,
                    [vendor]: formData.onlineVendors[vendor] || createVendorDetails(),
                },
            })
        } else {
            setFormData({
                ...formData,
                vendorOrder: _.without(formData.vendorOrder, vendor),
                onlineVendors: _.omit(formData.onlineVendors, vendor)
            });
        }
    }, [formData])

    const onVendorSort = useCallback(({ oldIndex, newIndex }) => {
        if (!formData) {
            return;
        }
        setFormData({
            ...formData,
            vendorOrder: arrayMove(formData.vendorOrder, oldIndex, newIndex),
        })
    }, [formData])

    const formErrors = useMemo(() => {
        return formData ? DomainFormValidationService.getValidationErrors(formData) : null;
    }, [formData]);

    const isNewDomain = useMemo(() => {
        return !domain;
    }, [domain]);

    const SubmitForm = useCallback((event) => {
        event.preventDefault();
        if (!formData || !userContext.user || formErrors) {
            if (!formSubmitted) {
                setFormSubmitted(true);
            }
            return;
        }
    
        const apiCall = isNewDomain 
            ? DomainService.createDomain(formData, userContext.user, subscriptionId)
            : DomainService.updateDomain({
                ...domain,
                ...formData,
            } as IDomain);
        
        setIsSubmitting(true);
        apiCall
            .then(({data: savedDomain}) => {
                dispatch(fetchDomains())
                setIsSubmitting(false);
                setIsDirty(false);
                onSaved?.(savedDomain);
            })
            .catch(error => {
                // TODO error handling
                console.log('error: ', error.response);
                setIsSubmitting(false);
            });    
    }, [formData, userContext.user, formErrors, domain, subscriptionId, formSubmitted, dispatch, onSaved, isNewDomain]);

    return (
        <Container>
            {formData && (
                <>
                    <FormWrapper onSubmit={SubmitForm}>
                        <Prompt
                            when={isDirty}
                            message="You have unsaved changes, are you sure you want to leave?"
                        />
                        <FormColumn>
                            <FormRow>
                                <Input
                                    label={
                                        <>
                                            <span>Domain Name</span>
                                        </>
                                    }
                                    name="domainName"
                                    id="domainName"
                                    type="text"
                                    value={formData.domainName}
                                    onChange={({ target: { value }}) => setFormData({ ...formData, domainName: value })}
                                    error={formErrors?.domainName}
                                    formSubmitted={formSubmitted}
                                />
                            </FormRow>
                            <FormRow>
                                <fieldset>
                                    <FullWidthInputLabel>Pick the platforms that supply buying options for your products.</FullWidthInputLabel>
                                    {allVendors.map(vendorName => (
                                        <span key={vendorName}>
                                            <Checkbox
                                                name="domain_vendors"
                                                value={vendorName}
                                                checked={formData.vendorOrder.includes(vendorName)}
                                                onChange={() => toggleIncludeVendor(vendorName)}
                                                color="primary"
                                            />
                                            <VendorNameLabel>{vendorName}</VendorNameLabel>
                                            <br/>
                                        </span>
                                    ))}
                                </fieldset>
                            </FormRow>
                            {formData.vendorOrder.length > 1 && (
                                <FormRow>
                                    <SortVendorSection>
                                        <InputLabel>Choose the display order of the vendors</InputLabel>
                                        <SortableVendors 
                                            items={formData.vendorOrder} 
                                            onSortEnd={onVendorSort} 
                                            helperClass="sortable-vendors"
                                        />
                                    </SortVendorSection>
                                </FormRow>
                            )}
                            {formData.vendorOrder.map(vendorName => (
                                <FormRow>
                                    <StyledSellerDetailsForm
                                        key={vendorName}
                                        vendor={vendorName}
                                        details={formData.onlineVendors[vendorName]}
                                        onChange={(details) => setFormData({
                                            ...formData,
                                            onlineVendors: {
                                                ...formData.onlineVendors,
                                                [vendorName]: details
                                            }
                                        })}
                                    />
                                </FormRow>
                            ))}
                            <FormRow>
                                <Button 
                                    type="submit" 
                                    color="primary" 
                                    variant="contained" 
                                    disabled={isSubmitting}
                                    disableElevation
                                >{isNewDomain ? 'Continue' :  'Save Domain'}</Button>
                            </FormRow>
                        </FormColumn>
                    </FormWrapper>
                </>
            )}
        </Container>
    )
}

export default DomainForm;

import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components'
import IValidationError from '../models/IValidationError';
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from '@material-ui/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


export const InputContainer = styled.div`
    display: block;
    position: relative;
`;

export const InputLabel = styled.label`
    font-size: 14px;
    color: #666;
    margin-bottom: 3px;
`;

export const FullWidthInputLabel = styled(InputLabel)`
    display: block;
    select + & {
        margin-top: 15px;
    }
`;

export const ErrorContainer = styled.div<{ color: string; }>`
    font-size: 13px;
    position: absolute;

    ${({color}) => css`
        color: ${color};
    `};
`

const StyledIconButton = styled(IconButton)`
    &&& {
        position: absolute;
        right: 6px;
        top: 33px;
        z-index: 1;
    }
`

const StyledInput = styled.input<{ errorColor: string; invalid: boolean; }>`
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    padding: 2px 8px;
    min-height: 38px;
    outline-color: #2684ff;

    ${({invalid, errorColor}) => invalid && css`
        border-color: ${errorColor};
   `};
`;

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: React.ReactNode | React.ReactNode[];
    isClearable?: boolean;
    formSubmitted?: boolean;
    error?: IValidationError;
}
const Input = ({
    label,
    error,
    onBlur,
    isClearable,
    formSubmitted,
    ...inputProps
}: IProps) => {
    const theme = useTheme();
    const [hasBeenFocused, setHasBeenFocused] = useState(false);

    const showError = useMemo(() => {
        return error && (hasBeenFocused || formSubmitted);
    }, [hasBeenFocused, formSubmitted, error]);

    const onInnerBlur = useCallback((e) => {
        if (!hasBeenFocused) {
            setHasBeenFocused(true);
        }
        onBlur?.(e);
    }, [hasBeenFocused, onBlur])

    return (
        <InputContainer>
            <InputLabel htmlFor={inputProps.id}>{label}</InputLabel>
            <StyledInput 
                {...inputProps}
                onBlur={onInnerBlur}
                errorColor={theme.palette.error.main} 
                invalid={showError}
            />
            {isClearable && inputProps.onChange && inputProps.value && (
                <StyledIconButton
                    size="small"
                    aria-label={`Clear ${label}`}
                    onClick={() => inputProps.onChange({ target: { value: null }} as any)}
                >
                    <FontAwesomeIcon icon={faTimes as IconProp} />
                </StyledIconButton>
            )}
            {showError && (
                <ErrorContainer color={theme.palette.error.main}>{error.message}</ErrorContainer>
            )}
        </InputContainer>
    )
}

export default Input;

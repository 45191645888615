import { Button } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import React, { useCallback, useMemo } from 'react';
import { Link, RouteComponentProps, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, PageLoadingError, PageLoadingIndicator, PageTitle } from '../common/components/Page';
import { WIDGET_DEFAULTS } from '../models/IDomain';
import { useAppSelector } from '../redux/hooks';
import { domainsLoadErrorSelector, isLoadingDomainsSelector, subscriptionsLoadErrorSelector } from '../redux/selectors';
import BackToDashboard from './BackToDashboard';
import { RESOURCE_URL_ROOT } from './constants';

const PageWrapper = styled(PageContainer)`
    position: relative;
`

const InstructionsList = styled.ol`
`

const InstructionsListItem = styled.li`
    padding-left: 15px;
    font-size: 22px;
    margin-bottom: 32px;
`

const ListItemTitle = styled.h2`
    font-size: 22px;
    margin-bottom: 12px;
`

const ListItemDescription = styled.p`
    font-size: 14px;
    margin-bottom: 24px;
`

const StyledCodeBlock = styled.code`
    font-size: 12px;
    background: rgba(0,0,0,.1);
    padding: 2px 4px;
`

const CopyButton = styled(Button)`
    font-size: 12px;
`;


interface IRouteParams {
    subscriptionId: string;
    domainId: string;
}
const WidgetInstructionsPage = (props: RouteComponentProps) => {
    const { domainId, subscriptionId } = useParams<IRouteParams>();
    const isLoading = useAppSelector(isLoadingDomainsSelector);
    const domain = useAppSelector(state => state.subscriptions.currentSubscription?.domains.items.find(d => d.domainId === domainId));
    const loadingError = useAppSelector(state => (
        subscriptionsLoadErrorSelector(state)
        || domainsLoadErrorSelector(state)
    ));

    const jsCodeSnippet = useMemo(() => {
        return `<script type="text/javascript" src="${RESOURCE_URL_ROOT}${domain?.domainId}/c9s.js" ></script>`;
    }, [domain]);

    const cssCodeSnippet = useMemo(() => {
        return `<link rel="stylesheet" type="text/css" href="${RESOURCE_URL_ROOT}${domain?.domainId}/c9s.css" />`
    }, [domain])

    const WidgetTypeInstructions = useMemo(() => {
        if (domain?.widgetCustomization?.type === 'POPUP') {
            return (
                <InstructionsListItem>
                    <ListItemTitle>Add id to the popup target element</ListItemTitle>
                    <ListItemDescription>Add the following id to the element you want to click to show the Cartonomics popup.</ListItemDescription>
                    <pre>{`<button id="cartonomics-popup"></button>`}</pre>
  
                </InstructionsListItem>
            );
        } else if (domain?.widgetCustomization?.type === 'EMBEDDED') {
            return (
                <InstructionsListItem>
                    <ListItemTitle>Add id to the target element for the embedded results</ListItemTitle>
                    <ListItemDescription>Add the following id to the element you want your Cartonomics results to show inside of on the page.</ListItemDescription>
                    <pre>{`<div id="cartonomics-embedded"></div>`}</pre>
                </InstructionsListItem>
            );
        } else if (domain?.widgetCustomization?.type === 'SEARCH') {
            return (
                <InstructionsListItem>
                    <ListItemTitle>Add search elements onto the page</ListItemTitle>
                    <ListItemDescription>Add the following id to the element you want your Cartonomics results to show inside of on the page.</ListItemDescription>
                    <pre>{`<div id="cartonomics-embedded"></div>`}</pre>
                    <ListItemDescription>Add the following id to the input you want to act as the search input.</ListItemDescription>
                    <pre>{`<input id="cartonomics__search-box" type="text" />`}</pre>
                    <ListItemDescription>Add the following id to the button you want to act as the search button.</ListItemDescription>
                    <pre>{`<button id="cartonomics__search-button">Search</button>`}</pre>
                </InstructionsListItem>
            )
        }
        return null;
    }, [domain])

    const missingConfiguration = useMemo(() => {
        if (!domain){
            return;
        }
        const missingRequiredFields = !domain.widgetCustomization?.type;
        return missingRequiredFields;
    }, [domain])

    const copyToClipboard = useCallback((text) => {
        copy(text);
    }, []);

    return (
        <PageWrapper background={false}>
            <BackToDashboard domain={domain} /> 
            <PageTitle>Widget Installation Instructions</PageTitle>
            <PageLoadingIndicator show={isLoading} variant="block" />
            {loadingError && (
                <PageLoadingError />
            )}
            {missingConfiguration && (
                <PageLoadingError>
                    <div>It looks like you haven't finished configuring your widget yet.</div>
                     <div>
                        <Link to={`/subscription/${subscriptionId}/domain/${domainId}/widget/edit`}>Click here</Link>
                        &nbsp;to finish setting up the widget for your product page.
                    </div>
                </PageLoadingError>
            )}
            {domain && !missingConfiguration && (
                <InstructionsList>
                    <InstructionsListItem>
                        <ListItemTitle>Configure your widget</ListItemTitle>
                        <ListItemDescription>Configure and customize the widget for your domain <Link to={`/subscription/${domain.subscriptionId}/domain/${domain.domainId}/widget/edit`}>here</Link></ListItemDescription>
                    </InstructionsListItem>
                    {WidgetTypeInstructions}
                    <InstructionsListItem>
                        <ListItemTitle>Copy Javascript</ListItemTitle>
                        <ListItemDescription>Copy the code snippet and place it in the head or body of your product page.</ListItemDescription>
                        {jsCodeSnippet && (
                            <>
                                <CopyButton 
                                    onClick={() => copyToClipboard(jsCodeSnippet)}
                                    variant="outlined"
                                    color="primary"
                                >Copy</CopyButton><br/>
                                <StyledCodeBlock>{jsCodeSnippet}</StyledCodeBlock>
                            </>
                        )}
                    </InstructionsListItem>
                </InstructionsList>
            )}
        </PageWrapper>
    );
}
export default WidgetInstructionsPage;

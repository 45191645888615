import React from 'react';
import styled, { css } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core';


const ErrorContainer = styled.div<{ backgroundColor: string; textColor: string; }>`
    padding: 5px 10px;
    margin: 5px 0;
    font-size: 13px;
    display: flex;
    align-items: center;
    
    svg {
        margin-right: 10px;
    }

    ${({backgroundColor, textColor}) => css`
        background: ${backgroundColor};
        color: ${textColor};
   `};
`

interface IProps {
    error?: string;
    className?: string;
}

const ErrorMessage = ({
    error,
    className=''
}: IProps) => {
    const theme = useTheme();
    return error && (
        <ErrorContainer 
            className={className}
            backgroundColor={theme.palette.error.light}
            textColor={theme.palette.error.contrastText}
        >
            <FontAwesomeIcon icon={faExclamationCircle as IconProp} size="lg" />
            <span>{error}</span>
        </ErrorContainer>
    );
}

export default ErrorMessage;

import { NavDropdown, NavDropdownItem, SubNavContainer, SubNavItem} from "./NavDropdown"
import React, { useCallback } from 'react';
import { API } from "aws-amplify";
import UserService from "../services/UserService";
import { Link } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { ISubscriptionResponse } from "../models/ISubscription";
import { SELECTED_SUBSCRIPTION_ID_LOCAL_KEY } from "../subscriptions/constants";

const AccountNav = () => {
    const goToChargbee = useCallback(async () => {
        const { portal_session: { access_url }} = await API.get('linkToChargebeeAPI', '/chargebeePortalSession', null)
        window.open(access_url);
    }, [])

    const allSubscriptions = useAppSelector(state => state.subscriptions.subscriptionsList.items)

    const signOut = useCallback(() => {
        UserService.signOut()
            .then(() => {
                window.location.href = '/'
            })
            .catch(() => console.log('error signing out...'))
    }, [])

    const setSubscription = useCallback((subscription: ISubscriptionResponse) => {
        localStorage.setItem(SELECTED_SUBSCRIPTION_ID_LOCAL_KEY, subscription.subscriptionId);
        window.location.href = '/'
    }, [])

    return (
        <NavDropdown 
            target="Account"
            position="left"
        >
            <NavDropdownItem>
                <Link to="/profile">My Account</Link>
            </NavDropdownItem>
            <NavDropdownItem>
                <button onClick={goToChargbee}>Manage Subscriptions & Billing</button>
            </NavDropdownItem>
            {allSubscriptions?.length > 1 && (
                <SubNavItem>
                <NavDropdownItem><span>Choose Subscription</span></NavDropdownItem>
                <SubNavContainer isOpen position="right">
                    {allSubscriptions.map(sub => (
                        <NavDropdownItem key={sub.subscriptionId}>
                            <button onClick={() => setSubscription(sub)}>{sub.subscriptionId}</button>
                        </NavDropdownItem>
                    ))}
                </SubNavContainer>
            </SubNavItem>
            )}
            <NavDropdownItem>
                <button onClick={signOut}>Sign Out</button>
            </NavDropdownItem>
        </NavDropdown>
    )
}
export default AccountNav
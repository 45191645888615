import IValidationError from "../common/models/IValidationError";

export function createUUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        // eslint-disable-next-line eqeqeq, no-mixed-operators
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}

export const MISSING_PASSWORD_ERROR: IValidationError = {
    code: 'missing.password',
    message: 'Please enter your current password'
}

export const INVALID_PASSWORD_ERROR: IValidationError = {
    code: 'missing.password',
    message: 'Password must be at least 6 characters and contain no whitespace.'
}

export function validatePassword(pw: string) {
    return pw.length >=6 && !/[\s]/.test(pw)
}

import React from 'react'
import { css } from 'glamor'

import SignIn from './SignIn'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import { RouteComponentProps } from 'react-router-dom'
import { PageContainer } from '../common/components/Page'
import ErrorMessage from '../common/components/ErrorMessage'
import styled from 'styled-components'

const StyledErrorMessage = styled(ErrorMessage)`
  max-width: 400px;
  margin: 0 auto;
`

interface IProps extends RouteComponentProps {};
interface IState {
  errorMessage: string | null;
  currentState: string;
}
class Authenticator extends React.Component<IProps, IState> {
  state = {
    errorMessage: null,
    currentState: 'showSignIn'
  };
  switchState = (currentState: string) => {
    this.setState({
      currentState
    })
  }
  updateErrorMessage = (errorMessage: string | null) => {
    this.setState({ errorMessage })
  }
  render() {
    const { currentState } = this.state
    return (
      <PageContainer>
        <div style={styles.container}>
          { currentState === 'showSignIn'  && <SignIn {...this.props} updateErrorMessage={this.updateErrorMessage} />  }
          { currentState === 'showSignUp' && <SignUp {...this.props} updateErrorMessage={this.updateErrorMessage} switchState={this.switchState} /> }
          { currentState === 'showForgotPassword' && <ForgotPassword switchState={this.switchState} {...this.props} updateErrorMessage={this.updateErrorMessage} /> }
          <div {...css(styles.buttonContainer)}>
            {
              currentState === 'showSignIn' ? (
                <div {...css(styles.linkContainer)}>              
                  <p
                    onClick={() => this.switchState('showForgotPassword')}
                    {...css(styles.toggle)}
                  >Forgot your password?</p>
                </div>
                  ) : (
                <div {...css(styles.linkContainer)}>
                  <p
                    {...css(styles.toggle)}
                    onClick={() => this.switchState('showSignIn')}
                  >Already have an account? Sign In</p>
                </div>
              )
            }
          </div>
          {
            this.state.errorMessage && (
              <StyledErrorMessage
                error={this.state.errorMessage}
              />
            )
          }
        </div>
      </PageContainer>
    )
  }
}

export default Authenticator

const styles = {
  linkContainer: {
    marginTop: 30
  },
  container: {
    marginTop: 50
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  toggle: {
    paddingBottom: '10px',
    cursor: 'pointer',
    marginTop: 10,
    marginBottom: 0,
    // marginBottom: 0,
    borderBottom: '2px solid transparent',
    ':hover': {
      opacity: 0.6
    }
  }
}
import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import ConfirmModal from '../common/components/ConfirmModal';

const InviteTeamMembers = () => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <Button
                type="button"
                color="primary"
                variant="contained" 
                disableElevation
                onClick={() => setModalOpen(true)}
            >Invite Team Members</Button>
            {modalOpen && (
                <ConfirmModal
                    title="Invite Team Members"
                    onCancel={() => setModalOpen(false)}
                    onConfirm={() => setModalOpen(false)}
                    confirmText="Submit"
                >
                    
                </ConfirmModal>
            )}
        </>
    )
}
export default InviteTeamMembers;
import React, { useCallback } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import DomainForm from '../domains/DomainForm';
import { PageTitle, PageContainer, PageLoadingIndicator, PageLoadingError } from '../common/components/Page';
import BackToDashboard from './BackToDashboard';
import { useAppSelector } from '../redux/hooks';
import { domainsLoadErrorSelector, isLoadingDomainsSelector, subscriptionsLoadErrorSelector } from '../redux/selectors';

interface IRouteParams {
    subscriptionId: string;
    domainId: string;
}

const EditDomain = (props: RouteComponentProps) => {
    const { subscriptionId, domainId } = useParams<IRouteParams>();
    const domain = useAppSelector(state => state.subscriptions.currentSubscription?.domains.items.find(d => d.domainId === domainId));
    const isLoading = useAppSelector(isLoadingDomainsSelector);
    const loadingError = useAppSelector(state => (
        subscriptionsLoadErrorSelector(state)
        || domainsLoadErrorSelector(state)
    ));


    const onSaved = useCallback(() => {
        props.history.push(`/subscription/${subscriptionId}/domain/${domainId}/dashboard`);
    }, [props.history, subscriptionId, domainId])
    
    return (
      <PageContainer background={false}>
        <BackToDashboard domain={domain}/>
        <PageTitle>Edit Domain: {domain?.domainName}</PageTitle>
        <PageLoadingIndicator show={isLoading} variant="block" />
        {loadingError && (
            <PageLoadingError />
        )}
        {domain && (
            <DomainForm
                subscriptionId={subscriptionId}
                domain={domain}
                onSaved={onSaved}
            />
        )}
      </PageContainer>
    )
}

export default EditDomain;

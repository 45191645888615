import { createTheme } from '@material-ui/core/styles';
import Colors from './Colors';

const MaterialTheme = createTheme({
  palette: {
    primary: {
        light: Colors.BRAND_PRIMARY_LIGHT,
        main: Colors.BRAND_PRIMARY,
        dark: Colors.BRAND_PRIMARY_DARK,
    },
  },
});

export default MaterialTheme;

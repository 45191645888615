
import styled from "styled-components"
import Dropdown, { DropdownTarget, DropdownContainer } from "../common/components/Dropdown"
import Colors from "../common/style-vars/Colors"

export const NavDropdown = styled(Dropdown)`
    ${DropdownTarget} {
        padding-top: 5px;
        padding-bottom: 5px;
        color: ${Colors.BRAND_PRIMARY};
        border-bottom: 2px solid transparent;
        
        &:hover {
            border-bottom: 2px solid ${Colors.BRAND_PRIMARY};;
        };

        &:focus {
            outline: none;
        }
    }
    ${DropdownContainer} {
        overflow: visible;
    }
`

export const SubNavContainer = styled(DropdownContainer)`
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(calc(-100%));
    white-space: nowrap;
    display: none;
`;

export const SubNavItem = styled.div`
    position: relative;
    &:hover {
        ${SubNavContainer} {
            display: block;
        }
    }
`

export const NavDropdownItem = styled.li`
    list-style: none;

    > * {
        width: 100%;
        text-align: left;
        padding: .5rem 2.5rem .5rem 1rem;
        background: white;
        display: block;
        cursor: pointer;
        white-space: nowrap;
        color: #403b3b;
        text-decoration: none;
        min-width: 200px;
        border: none;
        border-left: 4px solid transparent;
        white-space: nowrap;

        &:hover {
            background: #f9f9f9;
            color: #5f5a5a;
            text-decoration: none;
            border-left-color: ${Colors.BRAND_PRIMARY}
        }
    }
`
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageContainer, PageTitle } from '../common/components/Page';

const KnowledgeBaseHome = (props: RouteComponentProps) => {
    return (
        <PageContainer background={false}>
            <PageTitle>Knowledge Base</PageTitle>
        </PageContainer>
    )
}
export default KnowledgeBaseHome;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import BoxShadows from '../style-vars/BoxShadows';

export const DropdownWrapper = styled.div`
    display: inline-block;
    position: relative;
`

export const DropdownTarget = styled.div<{isOpen: boolean; event: string;}>`
    padding: 10px;
    cursor: pointer;
    padding-right: 1.125em;
    position: relative;
    user-select: none;

    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 0.25em solid transparent;
        position: absolute;
        right: 0.25em;
        top: 50%;
    }
    ${({event}) => event === 'click' && css`
        &:after {
            opacity: 0;
        }
        
        &:hover,
        &:focus {
            &:after {
                opacity: 1;
            }
        }
    `}

    ${({ isOpen }) => isOpen 
        ? css`
            &:after {
                border-bottom-color: currentColor;
                transform: translateY(-0.3125em);
            }
        `
        : css`
            &:after {
                border-top-color: currentColor;
                transform: translateY(0px);
            }
        `
    }
`

interface IDropdownContainerProps {
    isOpen: boolean;
    position: 'left' | 'right';
}
export const DropdownContainer = styled.div<IDropdownContainerProps>`
    position: absolute;
    z-index: 1;
    left: 0;
    top: 100%;
    background: white;
    box-shadow: ${BoxShadows.CARD};
    min-width: 100%;
    border-radius: 4px;
    overflow: hidden;

    ${({ isOpen }) => isOpen 
        ? css`
            display: block;
        `
        : css`
            display: none;
        `
    }

    ${({ position }) => position === 'left'
        ? css`
            left: auto;
            right: 0;
        `
        : css`
            right: auto;
            left: 0;
        `
    }
`

interface IProps {
    target: React.ReactNode;
    event?: 'click' | 'hover';
    className?: string;
    position?: 'left' | 'right'
}

const Dropdown = ({
    target,
    children,
    className = '',
    event = 'click',
    position = 'right',
}: React.PropsWithChildren<IProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const onKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
            setIsOpen(!isOpen);
        }
    }, [isOpen]);

    const onTargetClick = useCallback(() => {
        if (event === 'click') {
            setIsOpen(!isOpen);
        }
    }, [event, isOpen])

    const onMouseEnter = useCallback(() => {
        if (event === 'hover') {
            setIsOpen(true);
        }
    }, [event])

    const onMouseLeave = useCallback(() => {
        if (event === 'hover') {
            setIsOpen(false);
        }
    }, [event])

    const onDropdownClick = useCallback((event)=> {
        if (event.target?.tagName === 'A') {
            setIsOpen(false);
        }
    }, [])

    useEffect(()=> {
        let documentEventListener: (event: any) => void;;
        if (isOpen && event === 'click') {
            documentEventListener = (event: any) => {
                if (wrapperRef?.current && !wrapperRef.current?.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener('click', documentEventListener);
        }
        return () => {
            if (documentEventListener) {
                document.removeEventListener('click', documentEventListener);
            }
        }
    }, [isOpen, event]);

    return (
        <DropdownWrapper
            className={className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={wrapperRef}
        >
            <DropdownTarget
                tabIndex={0}
                onKeyDown={onKeyDown}
                onClick={onTargetClick}
                isOpen={isOpen}
                event={event}
            >
                {target}
            </DropdownTarget>
            <DropdownContainer 
                position={position}
                isOpen={isOpen}
                onClick={onDropdownClick}
            >
                {children}
            </DropdownContainer>
        </DropdownWrapper>
    );

}
export default Dropdown;
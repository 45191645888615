import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MediaQueries from '../common/style-vars/MediaQueries';
import IDomain from '../models/IDomain';

const Wrapper = styled.div`
    text-align: right;
    margin-top: -10px;

    @media (min-width: ${MediaQueries.SM_MIN}) {
        margin-top: 0;
        float: right;
        position: relative;
        z-index: 1;
    }
`

const StyledLink = styled(Link)`
    font-size: 16px;
`
interface IProps {
    domain: IDomain;
}
const BackToDashboard = ({
    domain
}: IProps) => {
    return (
        <Wrapper>
            <StyledLink to={`/subscription/${domain?.subscriptionId}/domain/${domain?.domainId}/dashboard`}>&lt;&lt; Back to Dashboard</StyledLink>
        </Wrapper>
    );
}
export default BackToDashboard;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './common/App';
import registerServiceWorker from './utils/registerServiceWorker';
import config from './aws-exports';
import Amplify from 'aws-amplify';
import store from './store';
import { Provider } from 'react-redux';

Amplify.configure(config)

ReactDOM.render(
    <Provider store={store} >
        <App />
    </Provider>,
    document.getElementById('root')
);
registerServiceWorker();
import React from 'react'
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
  RouteComponentProps,
} from 'react-router-dom'
import UserContext from './UserContext'
import Header from './header/Header'
import Authenticator from './auth/Authenticator'
import Home from './common/Home'
import Private from './auth/Private'
import Profile from './user/Profile'
import NewDomain from './subscriptions/NewDomain'
import SubscriptionsPage from './subscriptions/SubscriptionsPage'
import styled from 'styled-components'
import WidgetInstructionsPage from './domains/WidgetInstructionsPage'
import EditDomain from './domains/EditDomain'
import CustomizeWidget from './domains/CustomizeWidget'
import TwoFactorSettings from './user/TwoFactorSettings'
import DomainDashboard from './domains/DomainDashboard'
import DomainProducts from './domains/DomainProducts'
import DomainAnalytics from './domains/DomainAnalytics'
import KnowledgeBaseHome from './help/KnowledgeBaseHome'
import RouteWatcher from './RouteWatcher'


interface IProps extends RouteComponentProps {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
}
class PrivateRoute extends React.Component<IProps> {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  context!: React.ContextType<typeof UserContext>;

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.context.updateCurrentUser()
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  unlisten = () => {};

  render() {
    const { component: Component, ...rest } = this.props
    const isAuthenticated = this.context.user && this.context.user.getUsername() ? true : false
    const isLoaded = this.context.isLoaded
    if (!isLoaded) return null

    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            <>
            <Component {...props} />
            <RouteWatcher />
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/auth",
              }}
            />
          )
        }}
      />
    )
  }
}

const NoMatch = ({ location }: {
  location: Location
}) => (
  <div>
    <h3>No match for <code>{location.pathname}</code></h3>
  </div>
)

const PrivateRouteConnected = withRouter(PrivateRoute);

const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > :last-child {
    flex: 1;
  }
`;

const Routes = () => (
  <Router>
    <PageWrapper>
      <Header />
      <Switch>
        <Route path='/auth' exact component={Authenticator} />
        <PrivateRouteConnected path='/' exact component={Home} />
        <PrivateRouteConnected path='/private' exact component={Private} />
        <PrivateRouteConnected path='/profile'  component={Profile} />
        <PrivateRouteConnected path='/user/two-factor-settings'  component={TwoFactorSettings} />
        <PrivateRouteConnected path='/subscription/:subscriptionId/domain/new' component={NewDomain} />
        <PrivateRouteConnected path='/subscription/:subscriptionId/domain/:domainId/widget/edit' component={CustomizeWidget} />
        <PrivateRouteConnected path='/subscription/:subscriptionId/domain/:domainId/widget/instructions' component={WidgetInstructionsPage} />
        <PrivateRouteConnected path='/subscription/:subscriptionId/domain/:domainId/edit' component={EditDomain} />
        <PrivateRouteConnected path='/subscription/:subscriptionId/domain/:domainId/dashboard' component={DomainDashboard} />
        <PrivateRouteConnected path='/subscription/:subscriptionId/domain/:domainId/products' component={DomainProducts} />
        <PrivateRouteConnected path='/subscription/:subscriptionId/domain/:domainId/analytics' component={DomainAnalytics} />
        <PrivateRouteConnected path='/subscription/:subscriptionId' component={SubscriptionsPage} />
        <PrivateRouteConnected path='/knowledgebase' component={KnowledgeBaseHome} />
        <Route component={NoMatch} />
      </Switch>
    </PageWrapper>
  </Router>
)

export default Routes

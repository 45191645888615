import { RootState } from "../store";

export const isLoadingDomainsSelector = ((state: RootState) => (
    state.subscriptions.subscriptionsList.loading 
    || state.subscriptions.currentSubscription?.domains.loading
));

export const subscriptionsLoadErrorSelector = ((state: RootState) => (
    state.subscriptions.subscriptionsList.error
));

export const domainsLoadErrorSelector = ((state: RootState) => (
    state.subscriptions.currentSubscription?.domains.error
));
import { useLayoutEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, PageLoadingIndicator } from '../common/components/Page';
import { DomainCard } from './styled-components';
import DomainDashboardHeader from './DomainDashboardHeader';
import { useAppSelector } from '../redux/hooks';
import { domainsLoadErrorSelector, isLoadingDomainsSelector, subscriptionsLoadErrorSelector } from '../redux/selectors';
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import { Auth } from 'aws-amplify';
import { AWS_REGION, AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, LAMBDA_FUNCTION_NAME } from "./constants";
import { Buffer } from 'buffer';

var config = {
    region: `${AWS_REGION}`,
    credentials: {
        'accessKeyId': `${AWS_ACCESS_KEY_ID}`,
        'secretAccessKey': `${AWS_SECRET_ACCESS_KEY}`
    }
}
var lambda = new LambdaClient(config);

const DashboardBody = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 15px;

    ${DomainCard} {
        width: calc(50% - 12.5px);
        margin-bottom: 25px;
    }
`

interface IRouteParams {
    subscriptionId: string;
    domainId: string;
}

const DomainDashboard = (props: RouteComponentProps) => {
    const { domainId, subscriptionId } = useParams<IRouteParams>();
    const domain = useAppSelector(state => state.subscriptions.currentSubscription?.domains.items.find(d => d.domainId === domainId));
    const isLoading = useAppSelector(isLoadingDomainsSelector);
    const loadingError = useAppSelector(state => (
        subscriptionsLoadErrorSelector(state)
        || domainsLoadErrorSelector(state)
    ));

    useLayoutEffect(() => {
        Auth.currentUserInfo().then((data) => {
            console.log('Current user:', data.attributes.email);
            var command = new InvokeCommand({
                FunctionName : `${LAMBDA_FUNCTION_NAME}`,
                Payload : new Uint8Array(Buffer.from('{"username" : "' + data.attributes.email + '", "domainId" : "' + domainId + '", "subscriptionId" : "' + subscriptionId + '"}'))
            });

            lambda.send(command).then((data) => {
                var response = JSON.parse(Buffer.from(data.Payload.buffer).toString());
                if (response && response.Status === 200) {
                    const options = {
                        url: response.EmbedUrl,
                        container: "#quicksightEmbed",
                        scrolling: "yes",
                        height: "700px",
                        width: "1000px",
                        iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
                        locale: "en-US",
                        footerPaddingEnabled: true,
                        sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls in dashboard embedding
                        printEnabled: false, // use this option to enable or disable print option for dashboard embedding
                        defaultEmbeddingVisualType: "AUTO_GRAPH" // this option only applies to experience embedding and will not be used for dashboard embedding
                    };
                    var dashboard = window['QuickSightEmbedding'].embedDashboard(options);
                    dashboard.on('error', () => {
                        console.log('An error occurred loading the dashboard');
                    });
                    dashboard.on('load', () => {
                        console.log('Successfully loaded dashboard!');
                    });
                }
            }).catch((error) => {
                console.log('error:', error);
            });
        }).catch((error) => {
            console.log('Failed to determine the currently logged in user:', error);
        });
    }, [domainId, subscriptionId])

    return (
        <>
            <DomainDashboardHeader 
                tab="MAIN"
            />
            <PageContainer background={false}>
                <DashboardBody>
                    <PageLoadingIndicator variant={'block'} show={isLoading} />
                    <div id="quicksightEmbed"></div>
                </DashboardBody>
            </PageContainer>
        </>
    )
}

export default DomainDashboard;

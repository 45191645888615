import React from 'react';
import ConfirmModal from '../common/components/ConfirmModal';

interface IProps {
    onCancel: () => void;
    onConfirm: () => void;
    mpn: string;
}
const DeleteProductModal = ({
    onCancel,
    onConfirm,
    mpn
}: IProps) => {

    return (
        <ConfirmModal
            onCancel={onCancel}
            onConfirm={() => onConfirm()}
            title={`Delete ${mpn}?`}
        >
        </ConfirmModal>
    )
}
export default DeleteProductModal;
import React from 'react';
import styled from 'styled-components';
import { Portal } from 'react-portal';
import { Button } from '@material-ui/core';
import MediaQueries from '../style-vars/MediaQueries';

const ModalBackground = styled.div`
    background: rgba(0,0,0,.25);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
`

const ModalContainer = styled.div`
    background: white;
    padding: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 20px);
    width: 100%;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0,0,0,.25);
    overflow: visible;
    max-height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    z-index: 6;

    @media (min-width: ${MediaQueries.SM_MIN}) {
        width: auto;
        min-width: 450px;
    }
`;

const ModalTitle = styled.h1`
    font-size: 24px;
    margin-bottom: 24px;
    font-weight: 300;
    
    @media (min-width: ${MediaQueries.SM_MIN}) {
        font-size: 32px;
    }
`;

const ModalContent = styled.div`
    overflow: auto;
    padding-bottom: 500px;
    margin-bottom: -500px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    button {
        margin-left: 10px;
    }
`;

interface IProps {
    onConfirm?: () => void;
    onCancel?: () => void;
    title?: React.ReactNode;
    confirmText?: string;
    cancelText?: string;
    className?: string;
}
const ConfirmModal = ({
    onConfirm,
    onCancel,
    title,
    children,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    className = '',
}: React.PropsWithChildren<IProps>) => {
    return (
        <Portal>
            <ModalBackground className={className}>
                <ModalContainer>
                    {title && (<ModalTitle>{title}</ModalTitle>)}
                    <ModalContent>
                        {children}
                    </ModalContent>
                    <ButtonsContainer>
                        {onCancel && (
                            <Button
                                type="button"
                                color="primary" 
                                variant="outlined" 
                                disableElevation
                                onClick={onCancel}
                            >{cancelText}</Button>
                        )}
                        {onConfirm && (
                            <Button
                                type="button"
                                color="primary" 
                                variant="contained" 
                                disableElevation
                                onClick={onConfirm}
                            >{confirmText}</Button>
                        )}
                    </ButtonsContainer>
                </ModalContainer>
            </ModalBackground>
        </Portal>
    );
}
export default ConfirmModal;
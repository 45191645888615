import React from 'react';
import Input from '../common/components/Input';
import { IVendorDetails } from '../models/IDomain';
import styled from 'styled-components';
import { Container, FormRow } from '../common/components/Form';
import Colors from '../common/style-vars/Colors';

const FormWrapper = styled.div`
    background: ${Colors.BRAND_PRIMARY_LIGHT + '0A'};
    padding: 5px 10px;
    border-radius: 4px;
    label {
        text-transform: capitalize;
    }
`

const Title = styled.h2`
    font-size: 18px;
    margin-bottom: 12px;
    text-transform: capitalize;
`
interface IProps {
    vendor: string;
    details: IVendorDetails | null | undefined;
    onChange: (details: IVendorDetails) => void;
    className?: string;
}
const SellerDetailsForm = ({
    vendor,
    details,
    onChange,
    className = ''
}: IProps) => {

    return details ? (
        <Container className={className}>
            <Title>{vendor} details</Title>
            <FormWrapper>
                <FormRow>
                    <Input
                        label={`${vendor} API Key`}
                        id={`${vendor}_apiKey`}
                        value={details.apiKey}
                        type="text"
                        onChange={({ target: { value }}) => onChange({ ...details, apiKey: value })}
                    />
                </FormRow>
                <FormRow>
                    <Input
                        label={`${vendor} API Secret`}
                        id={`${vendor}_apiSecret`}
                        value={details.apiSecret}
                        type="password"
                        onChange={({ target: { value }}) => onChange({ ...details, apiSecret: value })}
                    />
                </FormRow>
                <FormRow>
                    {/*<Input
                        label={`${vendor} Store Id`}
                        id={`${vendor}_storeId`}
                        value={details.storeId}
                        type="text"
                        onChange={({ target: { value }}) => onChange({ ...details, storeId: value })}
                    />*/}
                </FormRow>
                <FormRow>
                    {/*<Input
                        label={`${vendor} Exclude Seller Ids`}
                        id={`${vendor}_excludeSellerIds`}
                        value={details.excludeSellerIds}
                        type="text"
                        onChange={({ target: { value }}) => onChange({ ...details, excludeSellerIds: value })}
                    />*/}
                </FormRow>
                <FormRow>
                    {/*<Input
                        label={`${vendor} Seller Order`}
                        id={`${vendor}_sellerIdOrder`}
                        value={details.sellerIdOrder}
                        type="text"
                        onChange={({ target: { value }}) => onChange({ ...details, sellerIdOrder: value })}
                    />*/}
                </FormRow>
                <FormRow>
                    {/* TODO do we need this? */}
                    {/* <InputContainer>
                        <Checkbox 
                            name={`show-price--${vendor}`}
                            value={`show-price--${vendor}`}
                            checked={details.showPrice}
                            onChange={() => onChange({ ...details, showPrice: !details.showPrice })}
                            color="primary"
                        />
                        <InputLabel>Show vendor price</InputLabel>
                    </InputContainer> */}
                    {/* <InputContainer>
                        <Checkbox 
                            name={`hide-if-no-stock--${vendor}`}
                            id={`hide-if-no-stock--${vendor}`}
                            value={`show-price--${vendor}`}
                            checked={details.hideIfNoStock}
                            onChange={() => onChange({ ...details, hideIfNoStock: !details.hideIfNoStock })}
                            color="primary"
                        />
                        <InputLabel>Hide if not in stock</InputLabel>
                    </InputContainer> */}
                </FormRow>
            </FormWrapper>
        </Container>
    ) : null;
}
export default SellerDetailsForm;
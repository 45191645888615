import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from '@material-ui/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
`

const StyledIconButton = styled(IconButton)`
    padding: 5px 10px;
    height: 33px;
    width: 33px;
    margin: 0 5px;
`

interface IProps {
    page: number;
    numPages: number;
    onChangePage: (page: number) => void;
}
const Pagination = ({
    page,
    numPages,
    onChangePage,
}) => {

    return (
        <Wrapper>
            <StyledIconButton 
                onClick={() => onChangePage(page - 1)} 
                disabled={page === 1}
                size="medium"
                color="primary"
            >
                <FontAwesomeIcon icon={faAngleLeft as IconProp} />
            </StyledIconButton>
            <div>
                Page {page} of {numPages}
            </div>
            <StyledIconButton 
                onClick={() => onChangePage(page + 1)} 
                disabled={page === numPages}
                size="medium"
                color="primary"
            >
                <FontAwesomeIcon icon={faAngleRight as IconProp} />
            </StyledIconButton>
        </Wrapper>
    )
}
export default Pagination;

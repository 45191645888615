import React, { useEffect, useMemo } from 'react'
import { useAppSelector } from '../redux/hooks';
import NewSubscriptionWelcome from '../subscriptions/NewSubscriptionWelcome';
import { PageContainer } from './components/Page';
import { RouteComponentProps } from 'react-router-dom';
import LoadingIndicator from './components/LoadingIndicator';

const Home = ({
  history
}: RouteComponentProps) => {
  const selectedSubscription = useAppSelector((state) => state.subscriptions.currentSubscription);
  const isLoading = useAppSelector((state) => state.subscriptions.subscriptionsList.items.length === 0);
  
  const showWelcomeScreen = useMemo(() => {
    return !isLoading 
      && selectedSubscription.subscription !== null
      && selectedSubscription.domains.items.length === 0 
      && !selectedSubscription.domains.loading;
  }, [isLoading, selectedSubscription])

  useEffect(() => {
    if (selectedSubscription.domains.items.length > 0) {
      const firstDomain = selectedSubscription.domains.items[0];
      history.push(`/subscription/${firstDomain.subscriptionId}/domain/${firstDomain.domainId}/dashboard`);
    }
  }, [selectedSubscription, history]);


  return (
    <PageContainer background={false}>
       <LoadingIndicator show={isLoading} variant="block" margin={100}/>
      {showWelcomeScreen && (
        <NewSubscriptionWelcome subscription={selectedSubscription.subscription}/>
      )}
    </PageContainer>
  );
}

export default Home
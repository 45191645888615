import { ISubscriptionResponse } from "../models/ISubscription";
import React from 'react';
import styled from "styled-components";
import Card from "../common/components/Card";
import { Button } from '@material-ui/core';

const Container = styled.div`
    display: flex;
    justify-content: space-around;
`

const WelcomeCard = styled(Card)`
    text-align: center;
    padding: 20px 40px;
    width: 100%;
    max-width: 700px;
    margin-top: 50px;

    a:hover {
        color: white;
    }
`

const Title = styled.h1`
    font-size: 32px;
    margin-bottom: 15px;
`

const Description = styled.p`
    font-size: 16px;
`

interface IProps {
    subscription: ISubscriptionResponse;
}
const NewSubscriptionWelcome = ({
    subscription
}: IProps) => {
    return (
        <Container>
            <WelcomeCard>
                <Title>Welcome to Cartonomics!</Title>
                <Description>It looks like you haven't created any domains yet.<br/> Create a domain to start using Cartonomics on your webpage.</Description>
                <Button 
                    href={`/subscription/${subscription?.subscriptionId}/domain/new`} 
                    variant="contained" 
                    color="primary"
                >Create Domain</Button>
            </WelcomeCard>
        </Container>
    )
}
export default NewSubscriptionWelcome;
import React from 'react';
import { ISubscriptionResponse } from "../models/ISubscription";
import styled from 'styled-components'
import { PageContainer, PageTitle } from '../common/components/Page';


const SubscriptionInfo = styled.div`
  font-size: 12px;
`;

interface IProps {
  subscription: ISubscriptionResponse;
}
const SubscriptionDetail = ({
  subscription,
}: IProps) => {

  return (
    <PageContainer>
      <SubscriptionInfo>
        <PageTitle>Subscription: {subscription.subscription.id}</PageTitle>
        <div>Plan Id: {subscription.subscription.plan_id}</div>
        <div>Started: {new Date(subscription.subscription.started_at * 1000).toLocaleDateString()}</div>
        <div>Status: {subscription.subscription.status}</div>
        <div>Next Billing At: {new Date(subscription.subscription.next_billing_at * 1000).toLocaleString()}</div>
        <div>Trial End: {new Date(subscription.subscription.trial_end * 1000).toLocaleString()}</div>
      </SubscriptionInfo>
    </PageContainer>
  );
}

export default SubscriptionDetail;
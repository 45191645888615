import React, { useCallback } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { PageContainer, PageLoadingError, PageLoadingIndicator, PageTitle } from '../common/components/Page';
import BackToDashboard from './BackToDashboard';
import WidgetForm from './WidgetForm';
import { useAppSelector } from '../redux/hooks';
import { domainsLoadErrorSelector, isLoadingDomainsSelector, subscriptionsLoadErrorSelector } from '../redux/selectors';

interface IRouteParams {
    subscriptionId: string;
    domainId: string;
}
const CustomizeWidget = (props: RouteComponentProps) => {
    const { subscriptionId, domainId } = useParams<IRouteParams>();
    const domain = useAppSelector(state => state.subscriptions.currentSubscription?.domains.items.find(d => d.domainId === domainId));
    const isLoading = useAppSelector(isLoadingDomainsSelector);
    const loadingError = useAppSelector(state => (
        subscriptionsLoadErrorSelector(state)
        || domainsLoadErrorSelector(state)
    ));

    const onSaved = useCallback(() => {
        props.history.push(`/subscription/${subscriptionId}/domain/${domainId}/dashboard`);
    }, [props.history, subscriptionId, domainId])

    
    return (
        <PageContainer background={false}>
            <BackToDashboard domain={domain} />
            <PageTitle>Configure Widget</PageTitle>
            <PageLoadingIndicator variant={'block'} show={isLoading} />
            {loadingError && (
                <PageLoadingError />
            )}
            {domain && (
                <WidgetForm
                    subscriptionId={subscriptionId}
                    domain={domain}
                    onSaved={onSaved}
                />
            )}
        </PageContainer>
    )
}
export default CustomizeWidget;
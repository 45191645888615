import React from 'react'
import { css } from 'glamor'
import { Auth } from 'aws-amplify'
import UserContext from '../UserContext'
import { SignInCard, SubmitButton } from './SignIn'
import Colors from '../common/style-vars/Colors'

interface IProps {
  updateErrorMessage: (message: string | null) => void;
  switchState: (state: string) => void;
}
interface IState {
  username: string;
  confirmationCode: string;
  password: string;
  showConfirmation: boolean;
  requestPending: boolean;
}
class ForgotPassword extends React.Component<IProps, IState> {
  state = {
    username: '',
    confirmationCode: '',
    password: '',
    showConfirmation: false,
    requestPending: false,
  }
  static contextType = UserContext

  onChangeUsername = (username: string) => {
    this.props.updateErrorMessage(null)
    this.setState({ username })
  }

  onChangeConfirmationCode = (confirmationCode: string) => {
    this.props.updateErrorMessage(null)
    this.setState({ confirmationCode })
  }

  onChangePassword = (password: string) => {
    this.props.updateErrorMessage(null)
    this.setState({ password })
  }

  onGetAuthCode = () => {
    this.setState({
      requestPending: true,
    });
    Auth.forgotPassword(this.state.username)
      .then(() => {
        this.setState({ showConfirmation: true })
        this.setState({
        requestPending: false,
      });
      })
      .catch(err => {
        this.props.updateErrorMessage(err.message);
        this.setState({
          requestPending: false,
        });
      })
  }

  onResetPassword = () => {
    const { username, password, confirmationCode } = this.state
    this.setState({
      requestPending: true,
    });
    Auth.forgotPasswordSubmit(username, confirmationCode, password)
      .then(() => {
        this.props.switchState('showSignIn')
        this.setState({
          requestPending: false,
        });
      })
      .catch(err => {
        this.props.updateErrorMessage(err.message)
        this.setState({
          requestPending: false,
        });
      })
  }

  render() {
    return (
      <div {...css(styles.container)}>
        {
          !this.state.showConfirmation && (
            <SignInCard>
              <h2>Forgot Password?</h2>
              <input
                onChange={({ target: { value }}) => this.onChangeUsername(value)}
                {...css(styles.input)}
                placeholder='Email'
                
              />
              <SubmitButton 
                color="primary" 
                variant="contained" 
                disableElevation
                onClick={this.onGetAuthCode}
                disabled={this.state.requestPending}
              >
                Get Authentication Code
              </SubmitButton>
            </SignInCard>
          )
        }
        {
          this.state.showConfirmation && (
            <SignInCard>
              <input
                onChange={({ target: { value }}) => this.onChangeConfirmationCode(value)}
                {...css(styles.input)}
                placeholder='Confirmation Code'
              />
              <input
                onChange={({ target: { value }}) => this.onChangePassword(value)}
                {...css(styles.input)}
                type='password'
                placeholder='New Password'
              />
              <SubmitButton 
                color="primary" 
                variant="contained" 
                disableElevation
                onClick={this.onResetPassword}
                disabled={this.state.requestPending}
              >
                Reset Password
              </SubmitButton>
            </SignInCard>
          )
        }
      </div>
    )
  }
}

const styles = {
  input: {
    height: 40,
    marginBottom: '10px',
    border: 'none',
    outline: 'none',
    borderBottom: `2px solid ${Colors.BRAND_PRIMARY}`,
    fontSize: '16px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .3)'
    }
  },
  container: {
    flex: 1,
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
}

export default ForgotPassword

import React, { useCallback, useMemo, useState } from 'react';
import { Link, RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ConfirmModal from '../common/components/ConfirmModal';
import Dropdown, { DropdownTarget } from '../common/components/Dropdown';
import { NavDropdownItem } from '../header/NavDropdown';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { fetchDomains } from '../redux/subscriptionsSlice';
import DomainService from '../services/DomainService';
import DomainSelector from './DomainSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools, faTrashAlt, faEdit, faCode, faWrench, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from '@material-ui/core';
import MediaQueries from '../common/style-vars/MediaQueries';
import BoxShadows from '../common/style-vars/BoxShadows';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-left: 3%;
    padding-right: 3%;
    background: #f9f9f9;
    border-bottom: thin solid #f0f0f2;
    min-height: 70px;
    display: none;

    @media (min-width: ${MediaQueries.MD_MIN}) {
      display: flex;
    }
`

const MobileWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
  position: relative;

  @media (min-width: ${MediaQueries.MD_MIN}) {
      display: none;
  }
`

const MobileContainer = styled.div<{ isOpen: boolean }>`
  background: white;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  border-bottom: thin solid #f0f0f2;
  padding-top: 60px;
  padding-bottom: 10px;
  transform: translateY(calc(-100% + 54px));
  transition: .4s transform ease-in-out;
  background: #f9f9f9;
  box-shadow: none;

  > * {
      opacity: 0;
      transition: .4s opacity ease-in-out;
  }

  ${({ isOpen }) => isOpen && css`
    transform: translateY(0);
    box-shadow: ${BoxShadows.CARD};
    > * {
      opacity: 1
    }
  `}
`

const MobileMenuToggle = styled.div`
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 3;
`

const MobielMenuButton = styled(IconButton)`
&&& {
    width: 45px;
    height: 45px;
}
`

const TabContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-right: 25px;
`

const Tab = styled.div<{ selected: boolean; }>`
    border-radius: 4px 4px 0 0;
    border: 1px solid #f0f0f2;
    border-bottom-width: 0;
    margin: 0 2px;
    height: auto;
    text-transform: uppercase;
    font-size: 13px;
    transform: translateY(1px);

    ${({ selected }) => selected && css`
        background: white;
    `}
`

const TabLink = styled(Link)`
    display: inline-block;
    padding: 10px 16px;
    text-decoration: none;
    
    &:hover {
        text-decoration: none;
        background: white;
    }
`

const DomainActions = styled(Dropdown)`
    align-self: center;

    ${DropdownTarget} {
        font-size: 22px;
        background: white;
        line-height: 1;
        padding: 15px;
        text-align: center;
        vertical-align: center;
        border-radius: 4px;
        box-sizing: content-box;
        border: 1px solid #f0f0f2;

        &:after {
            display: none;
        }

        &:hover {
            background: #f0f0f2;
        }

        &:focus {
            outline: none;
        }
    }

`

const ActionIconWrapper = styled.div`
    display: inline-block;
    width: 25px;
`

const MobileNav = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const MobileNavItem = styled.li`
    margin: none;
    font-size: 1.3333rem;
    & + & {
        margin-top: 5px;
    }
    > * {
        padding: 2px 10px;
    }
`

interface IRouteParams {
    subscriptionId: string;
    domainId: string;
}
interface IProps {
    tab: 'MAIN' | 'PRODUCTS' | 'ANALYTICS';
}

const DomainDashboardHeader = ({
    history,
    tab,
}: IProps & RouteComponentProps) => {
    const { domainId, subscriptionId } = useParams<IRouteParams>();
    const dispatch = useAppDispatch();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const domainName = useAppSelector(state => (
        state.subscriptions.currentSubscription?.domains.items
            .find(d => d.domainId === domainId))?.domainId
    );

    const deleteDomain = useCallback(() => {
        DomainService.deleteDomain(subscriptionId, domainId)
            .then(() => {
                dispatch(fetchDomains())
                history.push(`/`);
            })
    }, [domainId, subscriptionId, history, dispatch]);

    const actionsIcon = useMemo(() => {
        return (<FontAwesomeIcon icon={faTools as IconProp} />)
    }, [])
    
    return (
        <>
            <Container>
                <DomainSelector 
                    domainId={domainId}
                    subscriptionId={subscriptionId}
                />
                <TabContainer>
                    <Tab selected={tab === 'MAIN'}><TabLink to={`/subscription/${subscriptionId}/domain/${domainId}/dashboard`}>Dashboard</TabLink></Tab>
                    <Tab selected={tab === 'PRODUCTS'}><TabLink to={`/subscription/${subscriptionId}/domain/${domainId}/products`}>Products</TabLink></Tab>
                    <Tab selected={tab === 'ANALYTICS'}><TabLink to={`/subscription/${subscriptionId}/domain/${domainId}/analytics`}>Analytics</TabLink></Tab>
                </TabContainer>
                <DomainActions
                    target={actionsIcon}
                    event='click'
                    position="left"
                >
                    <NavDropdownItem>
                        <Link to={`/subscription/${subscriptionId}/domain/${domainId}/edit`}>
                            <ActionIconWrapper><FontAwesomeIcon icon={faEdit as IconProp} /></ActionIconWrapper>Edit Domain
                        </Link>
                    </NavDropdownItem>
                    <NavDropdownItem>
                        <button onClick={() => setConfirmDelete(true)}>
                            <ActionIconWrapper><FontAwesomeIcon icon={faTrashAlt as IconProp} /></ActionIconWrapper>Delete Domain
                        </button>
                    </NavDropdownItem>
                    <NavDropdownItem>
                        <Link to={`/subscription/${subscriptionId}/domain/${domainId}/widget/instructions`}>
                            <ActionIconWrapper><FontAwesomeIcon icon={faCode as IconProp} /></ActionIconWrapper>Install Widget
                        </Link>
                    </NavDropdownItem>
                    <NavDropdownItem>
                        <Link to={`/subscription/${subscriptionId}/domain/${domainId}/widget/edit`}>
                            <ActionIconWrapper><FontAwesomeIcon icon={faWrench as IconProp} /></ActionIconWrapper>Configure Widget
                        </Link>
                    </NavDropdownItem>
                </DomainActions>
                {confirmDelete && (
                    <ConfirmModal 
                        onCancel={() => setConfirmDelete(false)}
                        onConfirm={deleteDomain}
                        confirmText="Delete"
                        title={`Delete ${domainName}?`}
                    >
                        Once you delete this domain, you cannot go back.
                    </ConfirmModal>
                )}
            </Container>
            <MobileWrapper>
                <DomainSelector 
                    domainId={domainId}
                    subscriptionId={subscriptionId}
                />
                <MobileMenuToggle>
                    <MobielMenuButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                        <FontAwesomeIcon icon={faEllipsisV as IconProp} />
                    </MobielMenuButton>
                </MobileMenuToggle>
                <MobileContainer isOpen={mobileMenuOpen}>
                    <MobileNav>
                        <MobileNavItem><Link to={`/subscription/${subscriptionId}/domain/${domainId}/dashboard`}>Dashboard</Link></MobileNavItem>
                        <MobileNavItem><Link to={`/subscription/${subscriptionId}/domain/${domainId}/products`}>Products</Link></MobileNavItem>
                        <MobileNavItem><Link to={`/subscription/${subscriptionId}/domain/${domainId}/analytics`}>Analytics</Link></MobileNavItem>
                        <MobileNavItem><Link to={`/subscription/${subscriptionId}/domain/${domainId}/edit`}>Edit Domain</Link></MobileNavItem>
                        <MobileNavItem><Link to={`/subscription/${subscriptionId}/domain/${domainId}/widget/edit`}>Configure Widget</Link></MobileNavItem>
                    </MobileNav>
                </MobileContainer>
            </MobileWrapper>
        </>
    )
}
export default withRouter(DomainDashboardHeader);

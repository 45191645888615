import React, { useContext, useMemo } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import UserContext from '../UserContext'
import styled, { css } from 'styled-components'
import Colors from '../common/style-vars/Colors'
import AccountNav from './AccountNav'
import MediaQueries from '../common/style-vars/MediaQueries'

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HeaderContainer = styled.div`
  align-items: center;
  width: 100%;
  background-color: white;
  display: flex;
  padding: 0;
  padding-left: 5px;
  border-bottom: thin solid #f0f0f2;

  @media (min-width: ${MediaQueries.MD_MIN}) {
    padding: 0 3%;
  }

`

const Logo = styled.img`
  padding-bottom: 2px;
  margin-right: 5px;
  height: 40px;

  @media (min-width: ${MediaQueries.MD_MIN}) {
    height: 60px;
  }
`

const HeaderTitle = styled.h1<{ isSignedIn: boolean }>`
  font-size: 1.5rem;
  font-weight: 300;
  color: ${Colors.BRAND_PRIMARY};
  margin: 0;
  text-align: left;
  margin-left: 10;
  display: none;

  .full-title {
    display: none;
  }

  ${({ isSignedIn }) => !isSignedIn && css`
    display: block;
  `}

  @media (min-width: ${MediaQueries.SM_MIN}) {
    display: block;
    .full-title {
      display: inline;
    }
  }

  @media (min-width: ${MediaQueries.MD_MIN}) {
    font-size: 1.75rem;
  }

`

const NavContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 1;
`

const StyledLink = styled(Link)`
  text-decoration: 'none';
  &:hover {
    text-decoration: none;
  }
`

const HomeLink = styled(StyledLink)`
  display: flex;
  align-items: center;
`


const Header  = ({
  location,
}: RouteComponentProps) => {

  const userContext = useContext(UserContext);

  const isAuthenticated = useMemo(() => {
    return !!userContext.user && !!userContext.user.getUsername();
  }, [userContext.user]);

  const isLoaded = useMemo(() => {
    return userContext.isLoaded;
  }, [userContext]);

  const isSignedIn = useMemo(() => isLoaded && isAuthenticated, [isLoaded, isAuthenticated]);

  const homeRoute = useMemo(() => {
    const domainPathRegex = /(\/subscription\/[^/]+\/domain\/[^/]+)/;
    if (domainPathRegex.test(location.pathname)) {
      return location.pathname.match(domainPathRegex)[0] + '/dashboard'
    }
    return '/'
  }, [location.pathname])
  
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <HomeLink to={homeRoute}>
          <Logo
            src={require('../assets/cartonomicsLogo.png')}
            alt="Cartonomics.ai Logo"
          />
          <HeaderTitle isSignedIn={isSignedIn}>Cartonomics <span className="full-title">Customer Portal</span></HeaderTitle>
        </HomeLink>
        <NavContainer>
          {
            isSignedIn && (  
              <>
                {/*<HelpNav />*/}
                <AccountNav />
              </>
          )}
        </NavContainer>
      </HeaderContainer>
    </HeaderWrapper>
  );
}

export default withRouter(Header);

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import IDomain from '../models/IDomain';
import { ISubscriptionResponse } from '../models/ISubscription';
import DomainService from '../services/DomainService';
import SubscriptionsService from '../services/SubscriptionsService';
import { RootState } from '../store';

export interface ISubscriptionsState {
    subscriptionsList: {
        items: ISubscriptionResponse[];
        loading: boolean;
        currentRequestId: string | null;
        error: any | null; // TODO standard error
    };
    currentSubscription: {
        subscription: ISubscriptionResponse | null;
        domains: {
            items: IDomain[];
            loading: boolean;
            currentRequestId: string | null;
            error: any | null;
        };
    };
}

const initialState: ISubscriptionsState = {
    subscriptionsList: {
        items: [],
        loading: false,
        currentRequestId: null,
        error: null,
    },
    currentSubscription: {
        subscription: null,
        domains: {
            items: [],
            loading: false,
            currentRequestId: null,
            error: null,
        }
    }
}

export const fetchSubscriptions = createAsyncThunk(
    'sbscriptions/fetchSubscriptions',
    async (_, { getState, requestId }) => {
        const { subscriptions: { subscriptionsList: { loading, currentRequestId }}} = getState() as RootState;
        if (!loading || requestId !== currentRequestId) {
            return;
        }
        const response = await SubscriptionsService.getAllSubscriptions();
        return response;
    }
)

export const fetchDomains = createAsyncThunk(
    'sbscriptions/fetchDomains',
    async (_, { getState, requestId }) => {
        const { subscriptions: { currentSubscription: { subscription, domains: { loading, currentRequestId }}}} = getState() as RootState;
        if (!loading || requestId !== currentRequestId || !subscription?.subscriptionId) {
            return;
        }
        const { Items } = await DomainService.getDomainsForSubscription(subscription?.subscriptionId);
        return Items;
    }
)

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        setCurrentSubscription: (state, action: PayloadAction<string>)=> {
            const subscriptionId = action.payload;
            const matchingSubscription = state.subscriptionsList.items
                .find(sub => sub.subscriptionId === subscriptionId);
            
            if (matchingSubscription) {
                state.currentSubscription.subscription = matchingSubscription;
                state.currentSubscription.domains.items = [];   
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchSubscriptions.pending, (state, action) => {
            if (!state.subscriptionsList.loading) {
                state.subscriptionsList.loading = true;
                state.subscriptionsList.currentRequestId = action.meta.requestId;
            }
        });
        builder.addCase(fetchSubscriptions.fulfilled, (state, action) => {
            const { requestId } = action.meta
            if (action.payload && state.subscriptionsList.loading && state.subscriptionsList.currentRequestId === requestId) {
                state.subscriptionsList.items = action.payload;
                state.subscriptionsList.loading = false;
                state.subscriptionsList.currentRequestId = null;
            }
        });
        builder.addCase(fetchSubscriptions.rejected, (state, action) => {
            const { requestId } = action.meta
            if (state.subscriptionsList.loading && state.subscriptionsList.currentRequestId === requestId) {
                state.subscriptionsList.loading = false;
                state.subscriptionsList.error = action.error;
                state.subscriptionsList.currentRequestId = null;
            }
        });

        builder.addCase(fetchDomains.pending, (state, action) => {
            const { currentSubscription: { domains: domainState }} = state;
            if (!domainState.loading) {
                domainState.loading = true;
                domainState.currentRequestId = action.meta.requestId;
            }
        });
        builder.addCase(fetchDomains.fulfilled, (state, action) => {
            const { requestId } = action.meta
            const { currentSubscription: { domains: domainState }} = state;
            if (action.payload && domainState.loading && domainState.currentRequestId === requestId) {
                domainState.items = action.payload;
                domainState.loading = false;
                domainState.currentRequestId = null;
            }
        });
        builder.addCase(fetchDomains.rejected, (state, action) => {
            const { requestId } = action.meta
            const { currentSubscription: { domains: domainState }} = state;
            if (domainState.loading && domainState.currentRequestId === requestId) {
                domainState.loading = false;
                domainState.error = action.error;
            }
        });
    }
})

export const {
    setCurrentSubscription,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    justify-self: flex-end;
    padding: 10px 3%;
    background: #f9f9f9;
    border-top: thin solid #f0f0f2;
`

const CopyRightText = styled.div`
    font-size: 13px;
    text-align: center;
    color: #666;
`

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <Container>
            <CopyRightText>Copyright &copy; {currentYear} Cartonomics</CopyRightText>
        </Container>
    )
}
export default React.memo(Footer);
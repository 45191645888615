import React, { useContext } from 'react'
import { PageContainer, PageTitle } from '../common/components/Page'
import TwoFactorSettings from './TwoFactorSettings'
import UserContext from '../UserContext'
import styled from 'styled-components'
import ChangePassword from './ChangePassword'
import InviteTeamMembers from './InviteTeamMembers'

const ProfileSection = styled.div`
  margin-bottom: 40px;
`

const ProfileSectionTitle = styled.h2`
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 22px;
`

const Details = styled.table`
  display: table;
  margin-bottom: 25px;
`
const DetailsAttribute = styled.tr`
  display: table-row;

  td {
    padding: 5px 7px;
  }

  td:first-child {
    font-weight: 600;
    padding-left: 0;
  }
`

const SecurityActions = styled.div`
  display: inline-flex;
  flex-direction: column;
  button {
    margin-bottom: 15px;
  }
`

const Profile = () => {
    const userContext = useContext(UserContext);
    return (
      <PageContainer background={false}>
        <PageTitle>My Account</PageTitle>
        <ProfileSection>
          <ProfileSectionTitle>User Details</ProfileSectionTitle>
          <Details>
            <tbody>
              <DetailsAttribute>
                <td>Name:</td>
                <td>{userContext.user.attributes['custom:first_name']} {userContext.user.attributes['custom:last_name']}</td>
              </DetailsAttribute>
              <DetailsAttribute>
                <td>Email:</td>
                <td>{userContext.user.attributes.email}</td>
              </DetailsAttribute>
              <DetailsAttribute>
                <td>Phone:</td>
                <td>{userContext.user.attributes.phone_number}</td>
              </DetailsAttribute>
            </tbody>
          </Details>
        </ProfileSection>

        <ProfileSection>
          <ProfileSectionTitle>Security</ProfileSectionTitle>
          <SecurityActions>
            <TwoFactorSettings />
            <ChangePassword />
          </SecurityActions>
        </ProfileSection>

        <ProfileSection>
          <ProfileSectionTitle>Team Management</ProfileSectionTitle>
          <InviteTeamMembers/>
        </ProfileSection>
      </PageContainer>
    )
}

export default Profile

import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
    text-align: center;
    color: #666;
    font-size: 18px;
    padding: 25px 10px;

    h1, h2, h3, h4, h5 {
        font-size: 22px;
        margin-bottom: 10px;
        color: #333;
    }
`
interface IProps {
    className?: string;
}
const NoResultsMessage = ({
    children,
    className = '',
}:React.PropsWithChildren<IProps>) => {
    return (
        <Root className={className}>
            <div>{children}</div>
        </Root>
    )
}
export default NoResultsMessage;
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Dropdown, { DropdownTarget } from '../common/components/Dropdown';
import Colors from '../common/style-vars/Colors';
import { NavDropdownItem } from '../header/NavDropdown';
import IDomain from '../models/IDomain';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { fetchDomains, setCurrentSubscription } from '../redux/subscriptionsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import MediaQueries from '../common/style-vars/MediaQueries';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const Container = styled.div`
    min-width: 150px;
    margin-right: auto;
    z-index: 2;
    position: relative;
    background: #f9f9f9;
    width: 100%;
    min-height: 53px;

    @media (min-width: ${MediaQueries.MD_MIN}) {
      width: auto;
      min-height: none;
      background: none;
    }
`;


const StyledDropdown = styled(Dropdown)`
    ${DropdownTarget} {
        font-size: 22px;
        color: ${Colors.BRAND_PRIMARY};

        &:after {
            opacity: 1;
        }

        &:focus {
            outline: none;
        }
    }
`

const EMPTY_ARRAY = [];

interface IProps {
    domainId: string;
    subscriptionId: string;
}
const DomainSelector = ({
    domainId,
    subscriptionId,
}: IProps) => {
    const dispatch = useAppDispatch();
    const currentSubscription = useAppSelector((state) => state.subscriptions.currentSubscription);

    useEffect(() => {
        if (currentSubscription.subscription && currentSubscription.subscription?.subscriptionId !== subscriptionId) {
            dispatch(setCurrentSubscription(subscriptionId));
            dispatch(fetchDomains())
        }
    }, [subscriptionId, currentSubscription, dispatch]);

    const domainList: IDomain[] = useMemo(() => {
        if (currentSubscription.subscription?.subscriptionId === subscriptionId) {
            return currentSubscription.domains.items;
        }
        return EMPTY_ARRAY;
    }, [currentSubscription, subscriptionId]);

    const selectedDomain = useMemo(() => {
        return domainList.find(dom => dom.domainId === domainId)
    }, [domainList, domainId])

    const addIcon = useMemo(() => {
        return (<FontAwesomeIcon icon={faPlus as IconProp} />)
    }, []);

    return (
        <Container>
            {selectedDomain && (
                <StyledDropdown
                    target={selectedDomain?.domainName}
                >
                    {domainList.map(domain => (
                        <NavDropdownItem 
                            key={domain.domainId}
                        >
                            <Link to={`/subscription/${domain.subscriptionId}/domain/${domain.domainId}/dashboard`}>{domain.domainName}</Link>
                        </NavDropdownItem>
                    ))}
                    <NavDropdownItem >
                        <Link to={`/subscription/${selectedDomain?.subscriptionId}/domain/new`}>{addIcon}&nbsp;&nbsp; Add New Domain</Link>
                    </NavDropdownItem>
                </StyledDropdown>  
            )}
        </Container>
    )
}
export default DomainSelector;

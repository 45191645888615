import styled from "styled-components";
import Card from "../common/components/Card";

export const DomainCard = styled(Card)`
    width: 100%;
`;

export const DomainCardTitle = styled.h2`
    font-size: 22px;
    margin-bottom: 12px;
    font-weight: 300;
`;
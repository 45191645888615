import IValidationError from "../common/models/IValidationError";
import { IDomainEditableFields, IWidgetCustomization } from "../models/IDomain";
import * as _ from 'underscore';

const NAME_MISSING_ERROR: IValidationError = {
    code: 'name.missing',
    message: 'Please enter a name for the Domain',
}

const BUY_NOW_BUTTON_TEXT_MISSING_ERROR: IValidationError = {
    code: 'buy.now.button.text.missing',
    message: 'Please enter text for the \'Buy Now\' button',
}

const QUERY_PARAMETER_NAME_TEXT_MISSING_ERROR: IValidationError = {
    code: 'query.parameter.name.text.missing',
    message: 'Please enter a value for the query parameter name',
}

const NAME_ALREADY_USED: IValidationError = {
    code: 'name.already.used',
    message: 'That name is already used',
}

interface IDomainValidationResult {
    domainName?: IValidationError;
}

interface IWidgetValidationResult {
    mpnSelector?: IValidationError;
    manufacturerSelector?: IValidationError;
    buyNowButtonText?: IValidationError;
    queryParameterNameText?: IValidationError;
}

class DomainFormValidationService {
    public static getValidationErrors(domain: IDomainEditableFields, excludeNames: string[] = []): IDomainValidationResult | null {
        const validationResult = _.pick({
            domainName: this.validateDomainName(domain.domainName, excludeNames)
        }, (value) => !!value)
        return _.isEmpty(validationResult)
            ? null
            : validationResult;
    }

    public static getWidgetValidationErrors(widgetCustomization: IWidgetCustomization, excludeNames: string[] = []): IWidgetValidationResult | null {
        const validationResult: IWidgetValidationResult = {};
        if (!widgetCustomization.text.buyNowButton) {
            validationResult.buyNowButtonText = BUY_NOW_BUTTON_TEXT_MISSING_ERROR;
        }
        if (!widgetCustomization.text.queryParameterName) {
            validationResult.queryParameterNameText = QUERY_PARAMETER_NAME_TEXT_MISSING_ERROR;
        }
        return _.isEmpty(validationResult)
            ? null
            : validationResult;
    }

    private static validateDomainName(domainName: string, excludeNames: string[]): IValidationError | null {
        const isNameMissing = domainName.trim() === '';
        const domainNameUsed = excludeNames.includes(domainName.trim());
        if (isNameMissing) {
            return NAME_MISSING_ERROR;
        }
        if (domainNameUsed) {
            return NAME_ALREADY_USED;
        }
        return null;
    }
}

export default DomainFormValidationService;
import { useEffect } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import { useAppSelector } from "./redux/hooks";

interface IRouteParams {
    subscriptionId: string;
    domainId: string;
}

const RouteWatcher = ({
    history
}: RouteComponentProps) => {
    const { subscriptionId, domainId } = useParams<IRouteParams>();
    const subscriptionList = useAppSelector(state => state.subscriptions.subscriptionsList.items);
    const domainsList = useAppSelector(state => state.subscriptions.currentSubscription.domains.items);
    useEffect(() => {
        if (!subscriptionId || subscriptionList.length === 0) {
            return;
        }
        if (!subscriptionList.find(s => s.subscriptionId === subscriptionId)) {
            // invalid subscription id
            history.replace('/');
        }

        if (!domainId || domainsList.length === 0) {
            // TODO when no domains?
            return;
        }

        if (!domainsList.find(d => d.domainId === domainId)) {
            const firstDomainId = domainsList[0].domainId
            history.replace(`/subscription/${subscriptionId}/domain/${firstDomainId}/dashboard`);
        }
    }, [subscriptionId, subscriptionList, history, domainId, domainsList]);

    return null;
};
export default withRouter(RouteWatcher);
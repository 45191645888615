import React from 'react'
import { PageContainer } from '../common/components/Page'

class Private extends React.Component {
  render() {
    return (
      <PageContainer>
        <h1>Private Route</h1>
      </PageContainer>
    )
  }
}

export default Private

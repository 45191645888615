import React from 'react'
import { css } from 'glamor'
import { Auth } from 'aws-amplify'
import UserContext from '../UserContext'
import { RouteComponentProps } from 'react-router-dom'
import { IAuthUser } from '../models/IAuthUser'
import UserService from '../services/UserService'
import { Button } from '@material-ui/core';
import Card from '../common/components/Card'
import styled from 'styled-components'
import Colors from '../common/style-vars/Colors'

export const SignInCard = styled(Card)`
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  > form {
    display: flex;
    flex-direction: column;
  }

  h1,h2,h3 {
    text-align: left;
    margin:  0 0 20px;
  }
`

export const SubmitButton = styled(Button)`
  &&& {
    margin-top: 15px;
  }
`

interface IProps extends RouteComponentProps {
  updateErrorMessage: (message: string | null) => void;
}
interface IState {
  username: string;
  password: string;
  showConfirmation: boolean,
  showNewPasswordRequired: boolean,
  user: IAuthUser | null;
  authCode: string;
  newPassword: string;
  requestPending: boolean;
}
class SignIn extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showConfirmation: false,
      showNewPasswordRequired: false,
      user: null,
      authCode: '',
      newPassword: '',
      requestPending: false,
    }
  }

  static contextType = UserContext

  onChangeUsername = (value: string) => {
    this.props.updateErrorMessage(null);
    this.setState({
      username: value,
    });
  }

  onChangePassword = (value: string) => {
    this.props.updateErrorMessage(null);
    this.setState({
      password: value,
    });
  }

  onChangeNewPassword = (value: string) => {
    this.props.updateErrorMessage(null);
    this.setState({
      newPassword: value,
    });
  }

  onChangeAuthCode = (value: string) => {
    this.props.updateErrorMessage(null);
    this.setState({
      authCode: value,
    });
  }

  signIn = (event) => {
    event?.preventDefault();
    const { updateCurrentUser } = this.context
    this.setState({
      requestPending: true
    });
    UserService.signIn(this.state.username, this.state.password)
      .then((user: IAuthUser | any) => {
        if (!user.signInUserSession) {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.setState({ user, showNewPasswordRequired: true})
          } else {
            this.setState({ user, showConfirmation: true })
          }          
        } else {
          updateCurrentUser(user);
          document.location.href="/profile"
        }   
        this.setState({
          requestPending: false
        });     
      })
      .catch(err => {
        console.log('error signing in...: ', err)
        this.props.updateErrorMessage(err.message)
        this.setState({
          requestPending: false
        });
      })
  }
  
  confirmSignIn = (event) => {
    event?.preventDefault();
    this.setState({
      requestPending: true
    });

    Auth.confirmSignIn(this.state.user, this.state.authCode, this.state.user?.challengeName as undefined)
      .then(user => {
        document.location.href="/"
      })
      .catch(err => {
        console.log('error confirming signing in...: ', err)
        this.props.updateErrorMessage(err.message)
        this.setState({
          requestPending: false
        });
      });
  }

  completeNewPassword = (event) => {
    event?.preventDefault();
    const { history } = this.props
    const { updateCurrentUser } = this.context
    this.setState({
      requestPending: true
    });
    Auth.completeNewPassword(this.state.user, this.state.newPassword, null)
      .then(user => {
        if (!user.signInUserSession) {
          console.log(user)
          this.setState({ 
            user, 
            showNewPasswordRequired: false, 
            showConfirmation: true,
            requestPending: false,
          })                    
        } else {
          updateCurrentUser(user)
          history.push('/profile')
        }        
      })
      .catch(err => {
        console.log('error signing in...: ', err)
        this.props.updateErrorMessage(err.message)
        this.setState({
          requestPending: false
        });
      })
  }
  render() {
    return (
      <div {...css(styles.container)}>
        {
          !this.state.showConfirmation && !this.state.showNewPasswordRequired && (
            <SignInCard>
              <form onSubmit={this.signIn}>
                <h2>Sign In</h2>
                <input
                  onChange={({ target: { value }}) => this.onChangeUsername(value)}
                  {...css(styles.input)}
                  placeholder='Email'
                  type="text"
                />
                <input
                  type='password'
                  onChange={({ target: { value }}) => this.onChangePassword(value)}
                  {...css(styles.input)}
                  placeholder='Password'
                />
                <SubmitButton
                  type="submit"
                  color="primary" 
                  variant="contained" 
                  disableElevation
                  onClick={this.signIn}
                  disabled={this.state.requestPending}
                >Sign in</SubmitButton>
              </form>
            </SignInCard>
          )
        }
        {
          this.state.showConfirmation && !this.state.showNewPasswordRequired && (
            <SignInCard>
              <form onSubmit={this.confirmSignIn}>
                <input
                  onChange={({ target: { value }}) => this.onChangeAuthCode(value)}
                  {...css(styles.input)}
                  placeholder='Confirmation Code'
                  type="text"
                />
        
                <SubmitButton
                  type="submit"
                  color="primary" 
                  variant="contained" 
                  disableElevation
                  onClick={this.confirmSignIn}
                  disabled={this.state.requestPending}
                >Confirm Sign in</SubmitButton>
              </form>
            </SignInCard>
          )
        }
        {
          !this.state.showConfirmation && this.state.showNewPasswordRequired && (
            <SignInCard>
              <form onSubmit={this.completeNewPassword.bind(this)}>
                <input
                  type='password'
                  onChange={({ target: { value }}) => this.onChangeNewPassword(value)}
                  {...css(styles.input)}
                  placeholder='Enter New Password'
                />
                <SubmitButton 
                  type="submit"
                  color="primary" 
                  variant="contained" 
                  disableElevation
                  onClick={this.completeNewPassword.bind(this)}
                  disabled={this.state.requestPending}
                >Confirm Sign in</SubmitButton>
              </form>
            </SignInCard>
          )
        }        
      </div>
    )
  }
}

const styles = {
  input: {
    height: 40,
    marginBottom: '10px',
    border: 'none',
    outline: 'none',
    borderBottom: `2px solid ${Colors.BRAND_PRIMARY}`,
    fontSize: '16px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .3)'
    }
  },
  container: {
    flex: 1,
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
}

export default SignIn

import { API } from "aws-amplify";
import IProduct from "../models/IProduct";

export default class ProductsService {
    public static getProducsForDomain(domainId: string): Promise<IProduct[]> {
         return API.get('productAPI', '/product/' + domainId, {})
    }

    public static deleteProduct(domainId: string, productId: string): Promise<IProduct[]> {
        return API.del('productAPI', '/product/object/' + domainId + '/' + productId, {})
    }

    public static updateProduct(updatedProduct: IProduct): Promise<IProduct[]> {
        const requestData = {
            body: updatedProduct
        };
         return API.put('productAPI', '/product', requestData)
    }

    public static addProduct({
        mpn,
        manufacturer,
        vendorId,
        alias,
        domainId,
    }: IProduct): Promise<IProduct[]> {
        const requestData = {
            body: {
                mpn,
                manufacturer,
                vendorId,
                alias,
                domainId,
                productId: btoa(vendorId + mpn + manufacturer)
            }
        };
        return API.post('productAPI', '/product', requestData)
    }
}
import React, { useState } from 'react';
import ConfirmModal from '../common/components/ConfirmModal';
import { FormRow } from '../common/components/Form';
import Input from '../common/components/Input';

interface IProps {
    onCancel: () => void;
    onConfirm: (alias: string) => void;
    savedAlias: string;
    mpn: string;
}
const ProductAliasModal = ({
    onCancel,
    onConfirm,
    savedAlias,
    mpn
}: IProps) => {

    const [alias, setAlias] = useState(savedAlias || '');
    return (
        <ConfirmModal
            onCancel={onCancel}
            onConfirm={() => onConfirm(alias)}
            title={`Set Alias for ${mpn}`}
        >
            <FormRow>
                <Input
                    id="productAlias"
                    label="Product Alias"
                    type="text"
                    value={alias}
                    onChange={({target: { value }}) => setAlias(value || '')}
                    isClearable
                />
            </FormRow>
        </ConfirmModal>
    )
}
export default ProductAliasModal;
export type AvailableVendors = 'aliexpress' | 'nexar' | 'octopart' | 'supplyframe' | 'walmart';

export interface IVendorDetails {
    apiKey: string;
    apiSecret: string;
    storeId: string;
    excludeSellerIds: string;
    sellerIdOrder: string;
    showPrice: boolean;
    hideIfNoStock: boolean;
}

export type WidgetType = 'POPUP' | 'EMBEDDED' | 'SEARCH';

export type WidgetPopupPosition = 'CENTER' | 'LEFT' | 'RIGHT';

export type WidgetOrderBy = 'PART_NUMBER' | 'PRICE_LOW' | 'PRICE_HIGH' | 'QUANTITY' | 'VENDOR';

export interface IWidgetCustomization {
    type: WidgetType;
    popupPosition: WidgetPopupPosition;
    orderBy: WidgetOrderBy;
    hideIfNoStock: boolean;
    showCopyResultsButton: boolean;
    showExportResultsButton: boolean;
    displayPartNumber: boolean;
    displaySeller: boolean;
    displaySellerCountryCode: boolean;
    displayInventoryLevel: boolean;
    displayMOQ: boolean;
    displayPackaging: boolean;
    displayPrice: boolean;
    displayDatasheet: boolean;
    displayBuyNowButton: boolean;
    displayUpdatedDate: boolean;
    displayDeltaDate: boolean;
    combinePartResults: boolean;
    colors: {
        headerBackground: string;
        buyNowButton: string;
    };
    text: {
        buyNowButton: string;
        queryParameterName: string;
    };
}

export const WIDGET_DEFAULTS: IWidgetCustomization = {
    type: 'POPUP',
    orderBy: 'VENDOR',
    popupPosition: 'CENTER',
    hideIfNoStock: false,
    showCopyResultsButton: false,
    showExportResultsButton: false,
    displayPartNumber: true,
    displaySeller: true,
    displaySellerCountryCode: false,
    displayInventoryLevel: true,
    displayMOQ: true,
    displayPackaging: false,
    displayPrice: true,
    displayDatasheet: false,
    displayBuyNowButton: true,
    displayUpdatedDate: false,
    displayDeltaDate: false,
    combinePartResults: false,
    colors: {
        headerBackground: '#08275E',
        buyNowButton: '#ccf7ce',
    },
    text: {
        buyNowButton: 'Buy Now',
        queryParameterName: 'q'
    },
}
export interface IDomainEditableFields {
    alias: string;
    domainName: string;
    onlineVendors: {
        [key in AvailableVendors]?: IVendorDetails | null;
    };
    vendorOrder: AvailableVendors[];
    widgetCustomization: IWidgetCustomization;
}

export default interface IDomain extends IDomainEditableFields {
    createDate: number;
    updateDate: number;
    createUser: string;
    domainId: string;
    subscriptionId: string;
}

import React, { useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, PageTitle } from '../common/components/Page';
import IProduct from '../models/IProduct';
import ProductsService from '../services/ProductsService';
import DomainDashboardHeader from './DomainDashboardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import { IconButton } from '@material-ui/core';
import LoadingIndicator from '../common/components/LoadingIndicator';
import NoResultsMessage from '../common/components/NoResultsMessage';
import AddProductModal from './AddProductModal';
import { useAppSelector } from '../redux/hooks';
import ProductAliasModal from './ProductAliasModal';
import DeleteProductModal from './DeleteProductModal';
import Pagination from '../common/components/Pagination';
import SearchInput from '../common/components/SearchInput';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const StyledNoResultsMessage = styled(NoResultsMessage)`
    padding-top: 75px;
    padding-bottom: 75px;
    border:  1px solid #f0f0f2;
    border-top-width: 0;
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,.5);
`

const TableActions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    > * + *{
        margin-left: 10px;
    }
`

const TableWrapper = styled.div`
    position: relative;
    min-height: 200px;
    overflow: auto;
`

const Table = styled.table`
    background: white;
    border: 1px solid #f0f0f2;
    border-radius: 4px;
    width: 100%;
    min-width: 700px;

    thead {
        background: #f9f9f9;
    }


    td, th {
        border: 1px solid #f0f0f2;
        padding: 8px 12px;
    }
`
const ProductActionsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

interface IRouteParams {
    subscriptionId: string;
    domainId: string;
}
const DomainProducts = (props: RouteComponentProps) => {
    const { domainId, subscriptionId } = useParams<IRouteParams>();
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState<IProduct[]>(null);
    const [productAliasing, setProductAliasing] = useState<IProduct>(null);
    const [productDeleting, setProductDeleting] = useState<IProduct>(null);
    const [isAddingProduct, setIsAddingProduct] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [numPages, setNumPages] = useState<number>(1);
    const [search, setSearch] = useState('');

    const domainVendors = useAppSelector(state => (
        state.subscriptions.currentSubscription?.domains.items
            .find(d => d.domainId === domainId)?.vendorOrder || []
    ));

    const openProductAlias = useCallback((product: IProduct) => {
        setProductAliasing(product);
    }, [])

    const saveProductAlias = useCallback(async (alias: string) => {
        setIsLoading(true);
        try {
            await ProductsService.updateProduct({...productAliasing, alias});
            const newProducts = await ProductsService.getProducsForDomain(domainId)
            setProducts(newProducts);
            setProductAliasing(null);
            setIsLoading(false);
        } catch (e) {
            // TODO error handling
            console.error(e);
            setProductAliasing(null);
            setIsLoading(false);
        }
    }, [productAliasing, domainId]);

    const deleteProduct = useCallback(async () => {
        setIsLoading(true);
        try {
            await ProductsService.deleteProduct(domainId, productDeleting.productId);
            const newProducts = await ProductsService.getProducsForDomain(domainId)
            setProducts(newProducts);
            setProductDeleting(null);
            setIsLoading(false);
        } catch (e) {
            // TODO error handling
            console.error(e);
            setProductDeleting(null);
            setIsLoading(false);
        }
    }, [productDeleting, domainId])

    const saveProduct = useCallback(async (product: IProduct) => {
        setIsLoading(true);
        try {
            await ProductsService.addProduct(product);
            const newProducts = await ProductsService.getProducsForDomain(domainId)
            setProducts(newProducts);
            setIsAddingProduct(false);
            setIsLoading(false);
        } catch (e) {
            // TODO error handling
            setIsAddingProduct(false);
            setIsLoading(false);
        }
    }, [domainId])

    useEffect(() => {
        setIsLoading(true);
        // TODO use page and search
        ProductsService.getProducsForDomain(domainId).then(newProducts => {
            setProducts(newProducts);
            setIsLoading(false);
        }).catch(e => {
            // TODO: error handling
            console.error(e);
            setIsLoading(false);
        })
    }, [domainId, page, search])

    return (
        <>
            <DomainDashboardHeader 
                tab="PRODUCTS"
            />
            <PageContainer background={false}>
                <PageTitle>Products For Domain</PageTitle>
                <TableActions>
                    <SearchInput
                        onSearch={value => setSearch(value)}
                    />
                    <div>
                        <span data-tip data-for='addProduct'>
                            <IconButton
                                color="primary"
                                aria-label={`Add Product`}
                                onClick={() => setIsAddingProduct(true)}
                            >
                                <FontAwesomeIcon icon={faPlus as IconProp} />
                            </IconButton>
                        </span>
                        <ReactTooltip id='addProduct' effect="solid" place="top" clickable>
                            Add Product
                        </ReactTooltip>
                    </div>
                </TableActions>
                <TableWrapper>
                    <Table>
                        <thead>
                            <tr>
                                <th>Manufacturer</th>
                                <th>Product</th>
                                <th>Alias</th>
                                <th>Vendor</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products?.map(product => (
                                <tr key={product.productId}>
                                    <td>{product.manufacturer}</td>
                                    <td>{product.mpn}</td>
                                    <td>{product.alias || ''}</td>
                                    <td>{product.vendorId}</td>
                                    <td>
                                        <ProductActionsWrapper>
                                            <span data-tip data-for='editAlias' style={{ marginRight: '2px' }}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => openProductAlias(product)}
                                                    color="primary" 
                                                    aria-label="Add/Edit Product Alias"
                                                >
                                                    <FontAwesomeIcon icon={faExchangeAlt as IconProp}/>
                                                </IconButton>
                                            </span>
                                            <ReactTooltip id='editAlias' effect="solid" place="top" clickable>
                                                Add/Edit Alias
                                            </ReactTooltip>
                                            <span data-tip data-for='deleteProduct'>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setProductDeleting(product)}
                                                    color="secondary"
                                                    aria-label="Delete Product"
                                                >
                                                    <FontAwesomeIcon icon={faTrash as IconProp}/>
                                                </IconButton>
                                            </span>
                                            <ReactTooltip id='deleteProduct' effect="solid" place="top" clickable>
                                                Delete Product
                                            </ReactTooltip>
                                        </ProductActionsWrapper>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {products?.length > 0 && (
                        <Pagination 
                            page={page}
                            numPages={numPages}
                            onChangePage={(newPage) => setPage(newPage)}
                        />
                    )}
                    {products?.length === 0 && (
                        <StyledNoResultsMessage>
                            <h1>No products found for this domain.</h1>
                            <div>
                                <Link to={`/subscription/${subscriptionId}/domain/${domainId}/widget/instructions`}>Install the widget</Link>
                                &nbsp;on your product page and automatically create products.</div>
                        </StyledNoResultsMessage>
                    )}
                    <StyledLoadingIndicator show={isLoading} size="medium" />
                </TableWrapper>
            {productAliasing && (
                <ProductAliasModal
                    onCancel={() => setProductAliasing(null)}
                    onConfirm={(alias) => saveProductAlias(alias)}
                    savedAlias={productAliasing.alias}
                    mpn={productAliasing.mpn}
                />
            )}
            {isAddingProduct && (
                <AddProductModal
                    domainId={domainId}
                    onCancel={() => setIsAddingProduct(false)}
                    onSave={saveProduct}
                    vendorList={domainVendors}
                />
            )}
            {productDeleting && (
                <DeleteProductModal
                    mpn={productDeleting.mpn}
                    onCancel={() => {setProductDeleting(null)}}
                    onConfirm={deleteProduct}
                />
            )}
            </PageContainer>
        </>
    )
}

export default DomainProducts;

import { Button } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React, { useState, useContext, useCallback, useMemo } from 'react';
import ConfirmModal from '../common/components/ConfirmModal';
import ErrorMessage from '../common/components/ErrorMessage';
import { FormRow } from '../common/components/Form';
import Input from '../common/components/Input';
import IValidationError from '../common/models/IValidationError';
import { validatePassword, MISSING_PASSWORD_ERROR, INVALID_PASSWORD_ERROR } from '../services/utils';
import UserContext from '../UserContext';

const ChangePassword = () => {
    const userContext = useContext(UserContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const resetForm = useCallback(() => {
        setOldPassword('');
        setNewPassword('');
        setIsSubmitted(false);
    }, [])

    const onCancel = useCallback(() => {
        setModalOpen(false);
        resetForm();
    }, [resetForm]);

    const oldPasswordError: IValidationError = useMemo(() => {
        if (!oldPassword.trim()) {
            return MISSING_PASSWORD_ERROR;
        }

        if (!validatePassword(oldPassword)) {
             return INVALID_PASSWORD_ERROR;
        }
    }, [oldPassword]);

    const newPasswordError: IValidationError = useMemo(() => {
        if (!newPassword.trim()) {
            return MISSING_PASSWORD_ERROR;
        }
        if (!validatePassword(newPassword)) {
             return INVALID_PASSWORD_ERROR;
        }
    }, [newPassword]);

    const onSubmit = useCallback(() => {
        setIsSubmitted(true);
        if (newPasswordError || oldPasswordError) {
            return;
        }
        Auth.changePassword(userContext.user, oldPassword, newPassword)
            .then(() => {
                setModalOpen(false);
                resetForm();
            })
            .catch((err) => {
                setErrorMessage(err.message);
            });
    }, [oldPassword, newPassword, userContext, newPasswordError, oldPasswordError, resetForm])

    return (
        <>
            <Button
                type="button"
                color="primary"
                variant="contained" 
                disableElevation
                onClick={() => setModalOpen(true)}
            >Change Password</Button>
            {modalOpen && (
                <ConfirmModal
                    title="Change Password"
                    onCancel={onCancel}
                    onConfirm={onSubmit}
                    confirmText="Submit"
                >
                    <form onSubmit={onSubmit}>
                        <FormRow>
                            <Input
                                type="password"
                                id="oldPassword"
                                label="Old Password"
                                value={oldPassword}
                                onChange={({ target: { value }}) => setOldPassword(value)}
                                error={oldPasswordError}
                                formSubmitted={isSubmitted}
                            />
                        </FormRow>
                        <FormRow>
                            <Input
                                type="password"
                                id="newPassword"
                                label="New Password"
                                value={newPassword}
                                onChange={({ target: { value }}) => setNewPassword(value)}
                                error={newPasswordError}
                                formSubmitted={isSubmitted}
                            />
                        </FormRow>
                        {errorMessage && (
                            <ErrorMessage error={errorMessage} />
                        )}
                    </form>
                </ConfirmModal>
            )}
        </>
    )
}
export default ChangePassword;
import React, { useCallback } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import DomainForm from '../domains/DomainForm';
import { PageTitle, PageContainer } from '../common/components/Page';
import IDomain from '../models/IDomain';

const NewDomain = (props: RouteComponentProps) => {
    let { subscriptionId } = useParams<{ subscriptionId: string}>();
    // TODO check if it's a valid id and redirect

    const onSaved = useCallback((domain: IDomain) => {
      props.history.push(`/subscription/${subscriptionId}/domain/${domain.domainId}/widget/edit`);
    }, [props.history, subscriptionId])
    
    return (
      <PageContainer background={false}>
        <PageTitle>Create Domain</PageTitle>
        <DomainForm 
          subscriptionId={subscriptionId} 
          onSaved={onSaved}
        />
      </PageContainer>
    )
}

export default NewDomain;
import  React from 'react';
import SubscriptionDetail from './SubscriptionDetail';
import { useAppSelector } from '../redux/hooks';
import { PageContainer } from '../common/components/Page';

const SubscriptionsPage = () => {
    const selectedSubscription = useAppSelector((state) => state.subscriptions.currentSubscription.subscription);
 
    return (
        <PageContainer>
            {selectedSubscription && (
                <SubscriptionDetail subscription={selectedSubscription} />
            )}
        </PageContainer>
    )
};

export default SubscriptionsPage;
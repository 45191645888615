import React, { useCallback, useMemo, useState } from 'react';
import Select from 'react-select'
import ConfirmModal from '../common/components/ConfirmModal';
import { FormRow } from '../common/components/Form';
import Input, { ErrorContainer, FullWidthInputLabel, InputContainer } from '../common/components/Input';
import IValidationError from '../common/models/IValidationError';
import IValueAndLabel from '../common/models/IValueAndLabel';
import { AvailableVendors } from '../models/IDomain';
import IProduct from '../models/IProduct';
import * as _ from 'underscore';
import { useTheme } from '@material-ui/core';

interface IProductValidationResult {
    mpn?: IValidationError;
    manufacturer?: IValidationError;
    vendorId?: IValidationError;
}

const MISSING_MPN: IValidationError = {
    code: 'mpn.missing',
    message: 'Please enter a Product'
}

const MISSING_MANUFACTURER: IValidationError = {
    code: 'manufacturer.missing',
    message: 'Please enter a Manufacturer'
}

const MISSING_VENDOR: IValidationError = {
    code: 'vendor.missing',
    message: 'Please select a Vendor'
}

function validateProduct(product: IProduct): IProductValidationResult {
    const result: IProductValidationResult = {};
    if (!product.mpn.trim()) {
        result.mpn = MISSING_MPN;
    }
    if (!product.manufacturer.trim()) {
        result.manufacturer = MISSING_MANUFACTURER;
    }

    if (!product.vendorId) {
        result.vendorId = MISSING_VENDOR;
    }
    return _.isEmpty(result) ? null : result;
}

interface IProps {
    onCancel: () => void;
    onSave: (product: IProduct) => void;
    domainId: string;
    vendorList: AvailableVendors[];
}
const AddProductModal = ({
    onCancel,
    onSave,
    domainId,
    vendorList,
}: IProps) => {
    const theme = useTheme();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState<IProduct>({
        domainId,
        manufacturer: '',
        mpn: '',
        alias: '',
        lastUpdatedUTC: 0,
        productId: '',
        vendorId: '',
    })

    const vendorOptions: IValueAndLabel<AvailableVendors>[] = useMemo(() => {
        return vendorList.map(vendor => ({
            value: vendor,
            label: vendor,
        }))
    }, [vendorList]);

    const selectedVendorOption: IValueAndLabel<AvailableVendors> = useMemo(() => {
        return vendorOptions.find(o => o.value === formData.vendorId)
    }, [vendorOptions, formData.vendorId])

    const formErrors = useMemo(() => validateProduct(formData), [formData])

    const trySubmit = useCallback(() => {
        if (!isSubmitted) {
            setIsSubmitted(true);
        }
        if (formErrors) {
            return;
        }
        onSave(formData);
    }, [isSubmitted, formErrors, formData, onSave]);

    return (
        <ConfirmModal
            title="Add New Product"
            onConfirm={trySubmit}
            onCancel={onCancel}
            confirmText="Save"
        >
            <form onSubmit={trySubmit}>
                <FormRow>
                    <Input 
                        id="mpn"
                        type="text"
                        label="Part Number"
                        value={formData.mpn}
                        onChange={({ target: { value } }) => setFormData({ ...formData, mpn: value })}
                        formSubmitted={isSubmitted}
                        error={formErrors?.mpn}
                    />
                </FormRow>
                <FormRow>
                    <Input 
                        id="alias"
                        type="text"
                        label="Alias"
                        value={formData.alias}
                        onChange={({ target: { value } }) => setFormData({ ...formData, alias: value })}
                        formSubmitted={isSubmitted}
                    />
                </FormRow>
                <FormRow>
                    <Input 
                        id="manufacturer"
                        type="text"
                        label="Manufacturer"
                        value={formData.manufacturer}
                        onChange={({ target: { value } }) => setFormData({ ...formData, manufacturer: value })}
                        formSubmitted={isSubmitted}
                        error={formErrors?.manufacturer}
                    />
                </FormRow>
                <FormRow>
                    <InputContainer>
                        <FullWidthInputLabel>Vendor</FullWidthInputLabel>
                        <Select
                            value={selectedVendorOption}
                            options={vendorOptions} 
                            onChange={(option) => {
                                console.log(option);
                                setFormData({...formData, vendorId: option.value })
                            }}
                        />
                        {formErrors?.vendorId && isSubmitted && (
                            <ErrorContainer color={theme.palette.error.main}>{formErrors?.vendorId.message}</ErrorContainer>
                        )}
                    </InputContainer>
                </FormRow>
            </form>
        </ConfirmModal>
    );
}

export default AddProductModal;
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageContainer, PageTitle } from '../common/components/Page';
import DomainDashboardHeader from './DomainDashboardHeader';

// interface IRouteParams {
//     subscriptionId: string;
//     domainId: string;
// }
const DomainAnalytics = (props: RouteComponentProps) => {
    // const { domainId, subscriptionId } = useParams<IRouteParams>();

    return (
        <>
            <DomainDashboardHeader 
                tab="ANALYTICS"
            />
            <PageContainer background={false}>
                <PageTitle>Domain Analytics</PageTitle>
            </PageContainer>
        </>
    )
}

export default DomainAnalytics;
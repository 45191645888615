import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from '@material-ui/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const Wrapper = styled.div`
    display: flex;
    border: thin solid #f0f0f2;
    border-radius: 4px;
`

const Input = styled.input<{ isOpen: boolean; }>`
    border: none;
    padding: 0 10px;
    background: none;
    width: auto;
    width: 100px;
    transition: .5s width ease-out;
    height: 100%;

    ${({ isOpen }) => isOpen && css`
        width: 200px;
    `}
`

const SearchButton = styled(IconButton)`
&&& {
    background: #f9f9f9;
    & {
        border-radius: 0 4px 4px 0;
    }
}
`

const ClearButton = styled(IconButton)`
&&& {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
`

interface IProps {
    onSearch: (search: string) => void;
    initValue?: string;
    placeholder?: string;
}
const SearchInput = ({
    onSearch,
    placeholder = 'Search',
    initValue = '',
}: IProps) => {
    const [value, setValue] = useState(initValue);
    const [isFocused, setIsFocused] = useState(false);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            onSearch(value.trim())
        }
    }, [value, onSearch]);

    const clearValue = useCallback(() => {
        setValue('');
        onSearch('');
    }, [onSearch])

    return (
        <Wrapper>
            <div style={{ position: 'relative' }}>
            <Input 
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={({ target: { value: newValue }}) => setValue(newValue)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                isOpen={isFocused || value.trim().length > 0}
                onKeyDown={handleKeyDown}
            />
            {value.trim().length > 0 && (
                <ClearButton
                    onClick={clearValue}
                    size="small"
                >
                    <FontAwesomeIcon icon={faTimes as IconProp} />
                </ClearButton>
            )}
            </div>
            <SearchButton
                color="primary"
                onClick={() => onSearch(value.trim())}
            >
                <FontAwesomeIcon icon={faSearch as IconProp} />
            </SearchButton>
        </Wrapper>
    )
}

export default SearchInput;

import styled, { css } from "styled-components";
import React, { PropsWithChildren } from 'react';
import LoadingIndicator from "./LoadingIndicator";
import MediaQueries from "../style-vars/MediaQueries";

export const PageTitle = styled.h1`
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 300;
    position: relative;
    text-align: left;

    @media (min-width: ${MediaQueries.MD_MIN} ) {
        font-size: 32px;
    }
`

export const PageLoadingIndicator = styled(LoadingIndicator)`
    margin-top: 100px;
`

const PageLoadingErrorWrapper = styled.div`
    width: 100%;
    padding: 75px 10px;
    text-align: center;
    font-size: 1.125rem;
    color: #666;

    > :first-child {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }
`
export const PageLoadingError = (props: PropsWithChildren<any>) => {
    return (
        <PageLoadingErrorWrapper>
            {props.children || (
                <div>There was a problem loading your data.<br/> Please try again later.</div>
            )}
        </PageLoadingErrorWrapper>
    );
}

const ContainerDiv = styled.div<{ background: boolean }>`
    padding-left: 3%;
    padding-right: 3%;
    flex-grow: 1;
    padding-bottom: 50px;
    ${({ background }) => background && css`
        background: #f9f9f9;
    `}
`

const ContentWrapperDiv = styled.div`
    padding-top: 15px;
`;

interface IProps {
    background?: boolean;
    fullWidth?: boolean;
    className?: string;
}

export const PageContainer = ({
    background = true,
    fullWidth = false,
    className = '',
    children
}: React.PropsWithChildren<IProps>) => {
    return (
        <ContainerDiv className={className} background={background}>
            <ContentWrapperDiv>
                {children}
            </ContentWrapperDiv>
        </ContainerDiv>
    );
}

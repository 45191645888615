import { API } from "aws-amplify";
import { IAuthUser } from "../models/IAuthUser";
import IDomain, { IDomainEditableFields } from "../models/IDomain";
import { createUUID } from "./utils";

// TODO caching
export default class DomainService {
    public static getDomainsForSubscription(subscriptionId: string): Promise<{
        Items: IDomain[],
    }> {
         return API.get('domainAPI', '/domain/subscription/' + subscriptionId, {})
    }

    public static getDomainById(subscriptionId: string, domainId: string): Promise<IDomain> {
        return API.get('domainAPI', `/domain/object/${domainId}/${subscriptionId}`, {});
    }

    public static createDomain(data: IDomainEditableFields, user: IAuthUser, subscriptionId: string): Promise<{ data: IDomain}> {
        const now = new Date().getTime();
        const newDomain: IDomain = {
            ...data,
            subscriptionId,
            createUser: user.getUsername(),
            createDate: now,
            updateDate: now,
            domainId: createUUID().substring(20),
        }

        delete (newDomain as any)['subscriptions'];

        const requestData = {
            body: newDomain
        };
        return API.post('domainAPI', '/domain', requestData)
    }

    public static updateDomain(data: IDomain): Promise<{ data: IDomain}>  {
        const now = new Date().getTime();
        const updatedDomain: IDomain = {
            ...data,
            updateDate: now,
        }

        delete (updatedDomain as any)['subscriptions'];

        const requestData = {
            body: updatedDomain
        };
        return API.put('domainAPI', '/domain', requestData)
    }

    public static deleteDomain(subscriptionId: string, domainId: string): Promise<IDomain> {
        return API.del('domainAPI', `/domain/object/${domainId}/${subscriptionId}`, {});
    }
}